.allttam-toggle-input[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  transition: 0.7s;
  padding: 2px;
  width: 38px;
  height: 20px;
  border-radius: 32px;
}

.allttam-toggle-input[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 4.5px;
  left: 5px;
  transform: scale(1.1);
  transition: 0.5s;
  width: 12px;
  height: 12px;
  background: #fcfcfc;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%), inset 0px 2px 2px #ffffff,
    inset 0px -1px 1px rgb(0 0 0 / 10%);
  border-radius: 24px;
}

.allttam-toggle-input:checked[type="checkbox"] {
  background: #00b8ba;
}

.allttam-toggle-input:checked[type="checkbox"]:before {
  left: 21px;
}

.allttam-input-check > input {
  display: none;
}

.allttam-input-check input:checked + label::before {
  background-color: #00b8ba;
  border: 1px solid #00b8ba;
}

.allttam-input-check label::before {
  content: "";
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: 1.18188px solid rgba(15, 17, 21, 0.7);
  border-radius: 7px;
}

.allttam-input-check input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 1px;
}
