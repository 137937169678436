.group-name {
  flex-wrap: wrap;
}

.search-btn {
  flex-wrap: wrap;
}

.search-btn .save-btn {
  width: 10rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  gap: 0.5rem;
}

.product-label label {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #596380;
  padding-bottom: 0.5rem;
}

.product-label input {
  height: 2.9rem;
  border-radius: 10px;
  border: solid 1px #e4e6ed;
  background-color: #fff;
  max-width: 100%;
}

.product-label input::placeholder {
  font-size: 0.875rem;
  color: #596380;
  font-family: "SCDream4";
}

.select-image .form-select {
  width: 100%;
  height: 2.8rem;
  font-family: "SCDream5";
  color: #39415c;
}

.group-name h6 {
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #596380;
  margin: 0;
}

.dateSelector {
  position: relative;
  padding-top: 0.8rem;
  padding-left: 1.65rem;
  padding-bottom: 0.8rem;
  padding-right: 1.65rem;
  word-break: keep-all;
  font-family: "SCDream5";
  font-size: 0.725rem;
  color: #a7b0c7;
  overflow: hidden;
  margin: -3px;
  transition: 0.3s;
}

.payment-detail1 {
  flex-wrap: wrap;
  justify-content: center;
}

.payment-detail-inner {
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  overflow: hidden;
  overflow-x: auto;
}

@media (max-width: 450px) {
  .payment-detail-inner {
    display: none !important;
  }
}

/* .payment-detail-inner label {
  position: relative;
} */

/* .payment-detail-inner input {
  position: absolute;
  opacity: 0;
  visibility: hidden;
} */
/* 
.payment-detail-inner span {
  font-family: "SCDream5";
  font-size: 0.725rem;
  color: #a7b0c7;
  border-right: 1px solid #e4e6ed;
  padding-top: 0.8rem;
  padding-left: 1.65rem;
  padding-bottom: 0.8rem;
  padding-right: 1.65rem;
  overflow: hidden;
  margin: -3px;
  transition: 0.3s;
} */

.buttonDivder {
  height: 30px;
  border-right: 1px solid #e4e6ed;
}

.payment-detail-inner button:hover {
  background-color: #00b8ba;
  color: #fff;
  cursor: pointer;
}

.selectedButton {
  background-color: #00b8ba;
  color: #fff;
}

.payment-detail-inner span:active {
  background-color: #00b8ba;
  color: #fff;
  cursor: pointer;
}

.payment-detail-inner .last-child-span {
  border: 0;
}
.tableHead th {
  word-break: keep-all;
}

.tagBox {
  display: flex;
  gap: 0.1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.componentItemImg {
  width: 5rem;
  height: 5rem;
  display: inline-block;
  min-width: 100px;
  min-height: 100px;
}

.componentItemMainImg {
  width: 7rem;
  height: 7rem;
  display: inline-block;
}

.noneImg {
  width: 5rem;
  height: 5rem;
  border-radius: 0.625rem;
  background-color: #ccc;
  min-width: 100px;
  min-height: 100px;
}

.orderProductInfo {
  /* max-width: 100%; */
  overflow: hidden;
}

.toMarketAnchor {
  color: #596380;
}

.productManageTooltip2 {
  padding: 3px 10px;
  border-radius: 0.625rem;
  position: absolute;
  font-size: 11px;
  text-align: center;
  background-color: #000;
  opacity: 0.5;
  color: #fff;
  top: 50px;
}

.logoBtnBg {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-size: contain;
}

.nowPage {
  border-bottom: 2.5px solid #00b8ba;
}

.disabled {
  opacity: 0.3;
}

.pagingBox {
  max-width: 400px;
  overflow-x: scroll;
  height: 50px;
}

.productManageTooltip {
  padding: 3px 10px;
  border-radius: 0.625rem;
  position: absolute;
  font-size: 11px;
  bottom: 30px;
  left: -29px;
  text-align: center;
  background-color: #000;
  opacity: 0.5;
  color: #fff;
}

.productManageIconTooltip {
  padding: 3px 10px;
  border-radius: 0.625rem;
  position: absolute;
  font-size: 11px;
  top: 50px;
  left: 10px;
  text-align: center;
  background-color: #000;
  opacity: 0.5;
  color: #fff;
}

.toModal {
  cursor: pointer;
}

.viewSubImageContainer {
  display: flex;
}

.viewSubImage {
  width: 5rem;
  height: 5rem;
  border-radius: 0.625rem;
}
.viewMainImg {
  width: 20rem;
  height: 20rem;
}

.viewModal {
  font-family: "SCDream5";
}
.titleText {
  color: #909090;
}

.viewModal a {
  color: #39415c;
}

.viewModal p {
  margin: 0;
}
.viewModalLeftSide {
  width: 40%;
}

.viewModalRightSide {
  width: 60%;
}

.viewsSelectPeriod {
  border-radius: 0.625rem;
  border: solid 1px #e4e6ed;
  width: 11rem;
  max-width: 100%;
  padding: 0.5rem 0.625rem 0.5rem 0.938rem;
}

.viewsSelectPeriod:focus {
  outline: none;
}

.edit-check-mainText {
  font-size: 14px;
  color: #39415c;
  font-family: "SCDream6";
}

.edit-check-infoText {
  color: #00b8ba;
  font-size: 12px;
  font-family: "SCDream5";
}

.edit-checkbox {
  border: 1px solid #eff1f7;
  border-radius: 10px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}

.edit-check-box-hover:hover {
  border: 1px solid #00b8ba;
}
.edit-check-box-hover:hover > p {
  color: #00b8ba;
}
.edit-check-box-selected {
  border: 1px solid #00b8ba !important;
}
.product-edit-price-box {
  padding: 0 2rem;
}

.product-edit-price-box-text {
  font-family: "SCDream5";
  color: #596380;
  font-size: 14px;
}

.product-edit-price-box input,
.product-edit-price-box select {
  height: 2.3rem;
  width: 100px !important;
  box-sizing: border-box;
}

.inner-contents-padding {
  padding: 0 2rem;
}

.hidden {
  overflow: hidden;
}
