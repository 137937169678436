.logTableWrapper {
  overflow-y: auto;
  max-height: 300px;
}

.logTableWrapper table thead tr {
  word-break: keep-all;
}

.homeTooltip {
  position: absolute;
  background-color: #000;
  padding: 0.2rem 1rem;
  opacity: 0.5;
  color: #fff;
  border-radius: 50px;
}

.table-sticky > tr {
  position: sticky;
  top: 0;
  background-color: #eff1f7;
}

.graphImg {
  width: 100%;
  max-height: 280px;
}

.prepareOpacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040 !important;
  z-index: 10;
  border-radius: 0.625rem;
}

.prepareOpacity p {
  font-size: 25px;
  letter-spacing: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "SCDream5";
  color: #fff;
}
.homeLogTable > tbody > tr > td {
  padding: 0;
}

.homeLogTable > tbody > tr > td > div {
  font-family: "SCDream5";
  display: flex;
  justify-content: center;
  align-items: center;
  height: 43px;
}

.collectLogDetailButton {
  font-size: 10px;
  border: 1px solid #00b8ba;
  border-radius: 0.625rem;
  padding: 3px 10px;
  color: #00b8ba;
}

.collectLogDetailButton:hover {
  background-color: #00b8ba;
  color: white;
}

.collectLogDetailInfo {
  display: flex;
  margin: 0;
}

.collectLogDetailInfoTitle {
  width: 20%;
  text-align: start;
}

.collectLogDetailInfoContent {
  max-width: 80%;
  text-align: start;
}

/* .transelateLogTable > thead > tr {
  
} */
.transelateLogTable {
  font-size: 15px;
  font-family: "SCDream5";
}
.transelateLogTable > thead > tr > th {
  background-color: #eff1f7;
  padding: 0.5rem;
  text-align: center;
  color: #7e86a2;
}
.transelateLogTable > thead > tr > th:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}
.transelateLogTable > thead > tr > th:last-child {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.transelateLogTable > tbody > tr > td > div {
  margin-top: 0.5rem;
  color: #39415c;
  padding: 0.5rem;
  text-align: center;
}

.minHeight {
  min-height: 675px;
}

.eclips {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipCenter {
  position: absolute;
  left: 50%;
  bottom: 4rem;
}

.graph-label {
  font-family: "SCDream5";
  font-size: 14px;
}

.home-middle-row {
  height: 400px;
}

.homeNoticeComponent {
  font-family: "SCDream4";
  padding: 0.5rem;
}
.slideBanner {
  margin-top: 3rem;

  display: flex;
  justify-content: center;
}

.fixedBanner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 37%;
  height: 260px;
}

.exampleImg {
  background-color: #d9d9d9;
  width: 100%;
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smallbanner {
  width: 100%;
  height: 120px;
  border-radius: 10px;
}

.exampleImg > p {
  color: #fff !important;
}

.banner {
  position: absolute;
  width: 570px;
  height: 300px;
  border-radius: 1rem;
  top: 0;
}

.swiper {
  position: relative;
  width: 63%;
  height: 260px;
  margin-right: 12px;
}

.slick-list {
  margin: auto !important;
  position: relative;
  z-index: 2;
  width: 100%;
  height: 260px;
}

.slick-list img {
  width: 100%;
  border-radius: 1rem;
  height: 260px !important;
}

.slick-dots .slick-active button::before {
  font-size: 15px;
}

.bannerDeleteButton {
  position: absolute;
  top: -20px;
  right: 0;
}

.bannerSelectContainer {
  height: 50px;
}

.boxSizing {
  box-sizing: border-box;
}

.slick-dots {
  bottom: 10px !important;
  z-index: 2;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.slick-arrow {
  display: none;
}

.homePinnedNotice {
  white-space: nowrap;
  padding: 0.3px 4px;
  color: #d62828;
  border: 1px solid #d62828;
  border-radius: 6px;
}

.pinned-container {
  padding: 10px 5px;
  background-color: #f8f9fd;
  border-radius: 12px;
}

@media (max-width: 450px) {
  .md-d-none {
    display: none;
  }
  .md-f-c {
    flex-direction: column;
    gap: 0.5rem;
  }

  .md-f-c > div {
    margin-top: 1rem;
    width: 100% !important;
  }
}
