.memo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 10px;
  z-index: 10;
}

.memo-container button {
  z-index: 10;
}

.memo-item {
  padding: 10px;
}
