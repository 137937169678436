.serachingBoard {
  border-top-left-radius: 0 !important;
}

.selectedCollectItem {
  border: 2.5px solid #00b8ba;
}

.collectItemImg {
  width: 12rem;
  height: 12rem;
}

.collectItem {
  height: 22rem;
}

.itemTitleContainer {
  height: 4rem;
  overflow-y: auto;
}

.collectSticky {
  position: sticky;
  top: -16px;
  z-index: 8;
  background: #fff;
}

.newCollectSelect {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.collectAll {
  font-family: "SCDream6";
  color: #596380;
}

.subHeadSticky {
  position: sticky;
  top: 3.7rem;
  z-index: 8;
  background-color: #f8f9fd;
}

.spinnerContainer {
  margin-top: 7rem;
}

.collect-group-select-btn {
  position: absolute;
  top: 0;
  right: 0;
}

.collect-select-box {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  top: 50px;
  background-color: #fff;
  z-index: 5;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.collect-select-text {
  padding: 0.3rem;
  z-index: 2;
}
