.taobao-timeout-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clip {
  overflow: hidden;
}
