.progress-bar-bg {
  position: relative;
  height: 20px;
  width: 80%;
  background-color: #e9ecef;
  border-radius: 10px;
  margin: auto;
  margin-top: 50px;
  overflow: clip;
}

.progress-bar-inner {
  position: absolute;
  height: 20px;
  background-image: linear-gradient(to right, #ffb500, #ffe200);
  z-index: 2;
}
