.sidenav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  left: -14rem;
  width: 13rem;
  background: #ffb500;

  bottom: 0;
  z-index: 10;
  /* padding: 1rem 1.5rem; */
  padding-right: 0;
  transition: all 0.2s ease;
  height: 100vh;
}

.overflowYScroll {
  overflow-y: scroll;
  overflow-x: visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}
.overflowYScroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}

.sideNavArrow img {
  box-shadow: 0 3px 7px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 50%;
  padding: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
}

.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  height: 100px;
}

.logo2 {
  width: 6.25rem;
  margin: 0 3.813rem 4rem;
  z-index: 1;
}

.navItemList {
  padding-left: 13px;
}

.activeItem {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  background-color: #f8f9fd;
}

.activeItem span {
  color: #39415c;
}

.activeItem .icon {
  fill: #00b8ba;
}

.activeItem .guideIcon {
  stroke: #00b8ba;
}

.navItemList {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  justify-content: space-between;
}

.navItemList ul li {
  position: relative;
  z-index: 1;
}

/* .sidenav ul li a {
  color: #fff;
} */

.navItemList ul li a {
  padding: 0.5rem 0.5rem;
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.1s;
  margin-bottom: 0.5rem;
  justify-content: center;
}
.navItemList ul li .Link {
  padding: 0.5rem 0.5rem;
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 8px;
  position: relative;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  transition: 0.1s;
  margin-bottom: 0.5rem;
  justify-content: center;
}

.navItemListText {
  font-size: 1.063rem;
  color: #fff;
  font-family: "SCDream6";
  margin: 0 0 0 0.7rem;
}

.anchorWrapper {
  padding: 0 15px 0 15px;
}

.sidebarBottom {
  display: flex;
  flex-direction: row;
  padding: 13px 7px 13px 5px;
  border: 1px solid rgb(0 0 0 / 10%);
  border-radius: 10px;
}

.divider {
  border-right: 1px solid rgb(0 0 0 / 10%);
}

@media (min-width: 993px) {
  .desktop-nav-active .sidenav .sideNavArrow {
    transform: rotate(180deg);
  }

  .sidenav ul li a {
    padding-right: 13px;
  }

  .desktop-nav-active .sidebarBottom a p {
    font-size: 1.063rem;
    color: #fff;
    font-family: "SCDream5";
    margin-bottom: 0;
  }

  .desktop-nav-active .sidebarBottom a {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    gap: 0.25rem;
  }

  .desktop-nav-active .sidenav {
    width: 15.625rem;
    height: 100%;
  }

  .sidenav {
    /* left: 1rem;
    top: 1rem;
    bottom: 1rem; */
    left: 0rem;
    padding-left: 0rem;
    width: 4.5rem;

    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .sideNavArrow {
    position: absolute;
    top: 6.5rem;
    right: -1.2rem;
    cursor: pointer;
    z-index: 9;
    text-align: center;
    transition: all 0.3s ease;
    display: block;
    z-index: 2;
  }

  .sidenav ul li svg ~ span {
    display: none;
  }

  .desktop-nav-active .sidenav ul li svg ~ span {
    display: inline-block;
  }

  .desktop-nav-active .sidebarBottom {
    padding-left: 0;
  }

  .sidebarBottom {
    flex-direction: column;
    padding-left: 10px;
    width: 3rem;
    gap: 0.5rem;
  }

  .anchorWrapper {
    padding-right: 15px;
  }

  .divider {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
  }

  .sidebar-inner {
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid #f2ac00;
    padding-bottom: 0.5rem;
  }
  .desktop-nav-active .sidebarBottom a p {
    display: block;
    word-break: keep-all;
  }

  .sidebarBottom a img {
    width: 24px;
    height: 24px;
  }

  .sidebarBottom a p {
    display: none;
  }

  .logo2 {
    width: 3.25rem;
    margin: 1rem auto 2rem;
    z-index: 1;
  }

  .desktop-nav-active .logo2 {
    width: 6.25rem;
    margin: 0 3.813rem 4rem;
    z-index: 1;
  }
}

/*호버 설정*/

/* .sidenav ul li a:hover {
  border-radius: 25px;
  background-color: #f2ac00;
} */
.sidenav ul {
  display: flex;
  flex-direction: column;
}

.side-bar-none-div {
  height: 15px;
}
.side-bar-none-div-small {
  height: 10px;
}

.side-nav-top-bg {
  background-color: #f8f9fd;
  margin: 0;
  padding: 0;
}
.side-nav-top-radius {
  background-color: #ffb500;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0;
}
.side-nav-bottom-radius {
  background-color: #ffb500;
  border-top-right-radius: 15px;
  margin: 0;
  padding: 0;
}

.nav-tooltip {
  position: absolute;
  top: -35px;
  right: 3px;
  width: 60px;
  background-color: #ffffffcc;
  padding: 0.3rem;
  border-radius: 5px;
  color: #767d84;
  text-align: center;
  font-family: "SCDream3";
  font-size: 12px;
}

.nav-tooltip2 {
  position: absolute;
  top: -35px;
  right: -10px;
  width: 60px;
  background-color: #ffffffcc;
  padding: 0.3rem;
  border-radius: 5px;
  color: #767d84;
  text-align: center;
  font-family: "SCDream3";
  font-size: 12px;
}
