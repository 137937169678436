.weight200 {
  font-family: "SCDream2" !important;
}

.weight400 {
  font-family: "SCDream4" !important;
}

.weight500 {
  font-family: "SCDream5" !important;
}

.weight600 {
  font-family: "SCDream6" !important;
}

.weight700 {
  font-family: "SCDream7" !important;
}

.weight800 {
  font-family: "SCDream8" !important;
}

.weight900 {
  font-family: "SCDream9" !important;
}

.navy {
  color: #39415c;
}

.text6 {
  font-size: 6px;
}

.text8 {
  font-size: 8px;
}
.text10 {
  font-size: 10px;
}

.text12 {
  font-size: 12px;
}

.text13 {
  font-size: 13px;
}

.text14 {
  font-size: 14px;
}

.text16 {
  font-size: 16px;
}

.text18 {
  font-size: 18px;
}
.text20 {
  font-size: 20px;
}

.text24 {
  font-size: 24px;
}

.text25 {
  font-size: 25px;
}

.text35 {
  font-size: 35px;
}

.text40 {
  font-size: 40px;
}

.text50 {
  font-size: 50px;
}

.h1 {
  color: #000;
  font-size: 24px;
  font-family: "SCDream5";
}

.h2 {
  color: #000;
  font-size: 20px;
  font-family: "SCDream5";
}

.h3 {
  color: #000;
  font-size: 16px;
  font-family: "SCDream5";
}

.h4 {
  font-size: 16px;
  color: #596380;
  font-family: "SCDream5";
}

.h5 {
  color: #596380;
  font-size: 14px;
  font-family: "SCDream5";
}

.h6 {
  color: #596380;
  font-size: 12px;
  font-family: "SCDream5";
}

.grey {
  color: #596380;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.allttam-button {
  border-radius: 10px;
  box-sizing: border-box;
  font-family: "SCDream6";
  font-size: 14px;
  height: 40px;
  text-align: center;
}

.button-small {
  width: 90px;
}

.button-middle {
  width: 120px;
}

.button-big {
  width: 185px;
}

.green-button {
  color: #fff;
  background-color: #00bab8;
}

.navy-button {
  color: #fff;
  background-color: #39415c;
}

.green-button:hover {
  background-color: #38c7c9;
}

.white-border-button {
  border: 1px solid #d9d9d9;
  color: #596380;
  background-color: #fff;
}

.white-border-button:hover {
  border: 1px solid #38c7c9;
}

.yellow-button {
  background-color: #ffb500;
  color: #fff;
}

.orange-button {
  background-color: #ff8957;
  color: #fff;
}

.yellow-button:hover {
  background-color: #ffa412;
}

.white-green-text-button {
  border: 1px solid #d9d9d9;
  background-color: #fff;
  color: #00b8ba;
}

.white-green-text-button:hover {
  border: none;
  background-color: #38c7c9;
  color: #fff;
}

.gray-button {
  background-color: #c9cedb;
  color: #fff;
}
.gray-button:hover {
  background-color: #38c7c9;
}

.red-button {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  color: #cc0000;
}

.red-button:hover {
  border: none;
  background-color: #cc0000;
  color: #fff;
}

.allttam-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #eff1f7;
  color: #596380 !important;
  font-family: "SCDream5";
  border-radius: 10px;
  font-size: 14px;
}

.input-border-2 {
  border: 1px solid #d9d9d9;
}

.allttam-input:disabled {
  background-color: #f9f9f9;
}

.allttam-input:focus {
  outline: none;
  border: 1px solid #00b8ba;
}

.resize {
  resize: none;
}

.input-small {
  height: 30px;
}

.input-middle {
  height: 40px;
}

.text-center {
  text-align: center;
}

.shadow-md {
  box-shadow: 0px 4px 4px 0px #00000020;
}

.shadow-lg {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.allttam-table {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  text-align: center;
  color: #596380;
}

.allttam-table thead tr th {
  position: sticky;
  z-index: 10;
}

.allttam-table th {
  padding: 10px;
  white-space: nowrap;

  top: -1rem;

  background-color: #eff1f7;
}

.allttam-table td {
  padding: 0;
}

.allttam-table th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.allttam-table th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.allttam-table-item-border td div {
  font-family: "SCDream5";
  margin-top: 10px;
  padding: 10px;
  height: 50px;
  border-top: 2px solid #e4e6ed;
  border-bottom: 2px solid #e4e6ed;
}

.allttam-table-item-border td:first-child div {
  border-left: 2px solid #e4e6ed;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.allttam-table-item-border td:last-child div {
  border-right: 2px solid #e4e6ed;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.z10 {
  z-index: 10;
}

.plan-text {
  width: 15.75rem;
  color: rgba(57, 65, 92, 1);
}

@media (max-width: 450px) {
  .allttam-table thead tr th {
    z-index: 0;
  }
}
