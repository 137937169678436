@font-face {
  font-family: "SCDream6";
  src: url(../fonts/SCDream6.otf);
}

@font-face {
  font-family: "SCDream5";
  src: url(../fonts/SCDream5.otf);
}

@font-face {
  font-family: "SCDream4";
  src: url(../fonts/SCDream4.otf);
}

@font-face {
  font-family: "SCDream7";
  src: url(../fonts/SCDream7.otf);
}

@font-face {
  font-family: "SCDream9";
  src: url(../fonts/SCDream9.otf);
}

@font-face {
  font-family: "SCDream8";
  src: url(../fonts/SCDream8.otf);
}

@font-face {
  font-family: "S-CoreDream";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  margin: 0;
  font-size: 16px;
  background-color: #f8f9fd;
}

input,
select {
  cursor: pointer;
  font-size: 14px !important;
  color: #39415c !important;
  font-family: "SCDream5";
}

input::placeholder {
  font-family: "SCDream5";
  color: #bdc1ce !important;
  font-size: 14px !important;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input:focus,
button:focus,
textarea:focus {
  outline: none;
  border-color: #00b8ba !important;
}

img {
  max-width: 100%;
}

a:hover,
a:focus {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.cursor {
  cursor: auto;
}

a {
  text-decoration: none;
}

.main-hero-sec {
  padding: 1rem;
  /* overflow-y: auto; */
  min-height: 100vh;
}

.madebutton {
  all: unset;
  padding: 6.5px;
}

.notification-active {
  padding-right: 22rem;
}

.contentContainer {
  padding-left: 85px;
}

.desktop-nav-active .contentContainer {
  padding-left: 10px;
}

.care-helper-layout {
  /* display: flex; */
}

/* .notification-active .container {
  max-width: 100% !important;
} */

.desktop-nav-active .heroSec {
  padding-left: 16rem;
}

/* 
.side-navMenu {
    min-height: 100%;
} */

.hr1 {
  margin: 8px 10px 14px 10px;
  opacity: 0.1;
}

.headingOne {
  padding-top: 1rem;
}

.header-one-inner {
  margin-top: 3rem;
  padding: 1.25rem;
  padding-top: 0;
  padding-bottom: 0;
}

.header-one-inner-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.header-one-inner-1 h6 {
  margin: 0;
  color: #1f2534;
  font-family: "SCDream6";
  font-size: 1.125rem;
}

.a-btn {
  color: #fff;
  background-color: #00b8ba;
  border: 1px solid transparent;
  border-radius: 1.125rem;
  box-shadow: 0 3px 7px 0 rgb(0 184 186 / 30%);
  padding: 0.35rem 1.4rem;
  font-size: 0.875rem;
  transition: 0.3s;
  font-family: "SCDream6";
}

.a-btn:hover {
  color: #00b8ba;
  background-color: transparent;
  border: 1px solid #00b8ba;
}

.s1-inner {
  padding-right: 0.75rem;
}

.s1-inner h6 {
  margin: 0;
  font-size: 0.938rem;
  color: #596380;
  font-family: "SCDream4";
}

.s1-inner p {
  margin: 0;
  font-size: 0.938rem;
  color: #39415c;
  font-family: "SCDream5";
}

.s2-inner {
  padding-left: 0.75rem;
}

.acc-info {
  position: relative;
}

.acc-info::after {
  position: absolute;
  content: "";
  background-color: #e4e6ed;
  right: -0.9rem;
  top: -0.2rem;
  width: 1px;
  height: 70px;
}

.calendar {
  margin: auto;
  padding: 0.625rem 0.625rem 0.938rem 0.938rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 10px 0 #d3d3d3;
  background-color: #fff;
  margin-top: 2rem;
  margin-bottom: 1rem;
  transition: 0.3s;
  width: 8.438rem;
  height: 8.438rem;
  max-width: 100%;
  /* min-width: 10rem;
  min-height: 10rem; */
}

.cursor-pointer {
  cursor: pointer;
}

.calendar-inner svg {
  background-color: #00b8ba;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.65rem;
}

.calendar-text {
  display: flex;
  justify-content: flex-end;
}

.calendar h4 {
  /* width: 100%; */
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "SCDream7";
  color: #00b8ba;
  font-size: 1.875rem;
  padding-top: 1rem;
  transition: 0.3s;
}

.calendar p {
  font-size: 0.938rem;
  margin: 0;
  font-family: "SCDream6";
  color: #1f2534;
  transition: 0.3s;
}

.calendar:hover {
  background-color: #00b8ba;
}

.calendar:hover h4 {
  color: #fff;
}

.calendar:hover p {
  color: #fff;
}

.calendar-1 h4 {
  color: #ffb500;
}

.calendar-1 .calendar-inner svg {
  background-color: #ffb500;
}

.calendar-1:hover {
  background-color: #ffb500;
}

.calendar-2 h4 {
  color: #272e41;
}

.calendar-2 .calendar-inner svg {
  background-color: #39415c;
}

.calendar-2:hover {
  background-color: #39415c;
}

.calendar-3 h4 {
  color: #ff8957;
}

.calendar-3 .calendar-inner svg {
  background-color: #ff8957;
}

.calendar-3:hover {
  background-color: #ff8957;
}

.heading-two {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.heading-two-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading-two-inner h6 {
  margin: 0;
  color: #1f2534;
  font-family: "SCDream6";
  font-size: 1.125rem;
}

.heading-two-inner-1 {
  display: flex;
  align-items: center;
}

.heading-two-inner-1 p {
  margin: 0;
  color: #7e86a2;
  font-family: "SCDream4";
  font-size: 0.875rem;
}

.heading-two-inner-1 svg {
  width: 2.5rem;
  height: 2.5rem;
  display: block;
}

.under-heading-1 {
  padding: 1.125rem;
  transition: 0.3s;
}

.under-heading-1:hover {
  border-radius: 10px;
  background-color: #fff;
}

.under-heading-1 h6 {
  color: #39415c;
  font-size: 0.938rem;
  font-family: "SCDream5";
  margin: 0;
}

.under-heading-1 h6 span {
  width: 6px;
  height: 6px;
  background-color: #ffb500;
  display: inline-block;
  border-radius: 50%;
  margin: 4px 0 4px 9px;
}

.under-heading-1 p {
  color: #596380;
  font-size: 0.938rem;
  font-family: "SCDream4";
  margin: 0;
}

.heading-three {
  padding: 1.25rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  background-color: #fff;
}

.progress-col .progress {
  height: 10px;
  border-radius: 5px;
}

.progress-col .progress-bar {
  height: 10px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #ffb500, #ffe200);
}

.progress-col-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  margin-top: 1.5rem;
}

.progress-col-inner h6 {
  margin: 0;
  color: #39415c;
  font-family: "SCDream4";
  font-size: 0.875rem;
}

.progress-col-inner p {
  margin: 0;
  color: #7e86a2;
  font-size: 0.875rem;
  font-family: "SCDream4";
}

.progress-col-1 .progress-bar {
  background-image: linear-gradient(to right, #39415c, #596380);
}

.progress-col-2 .progress-bar {
  background-image: linear-gradient(to right, #00b8ba, #00cbc0);
}

.progress-col-3 .progress-bar {
  background-image: linear-gradient(to right, #4a22ae, #4632de);
}

.progress-col-4 .progress-bar {
  background-image: linear-gradient(to right, #fc763d, #ff8957);
}

.b-btn {
  padding: 0.35rem 1rem;
  font-family: "SCDream4";
  font-size: 0.938rem;
  box-shadow: none;
}

.c-btn {
  background-color: #eff1f7;
  color: #a7b0c7;
}

.heading-five-inner svg {
  border-radius: 50%;
  padding: 6px;
  display: block;
  width: 2rem;
  height: 2rem;
  transition: 0.5s;
}

.heading-five-inner svg:hover {
  padding: 6px;
  box-shadow: 4px 2px 7px 0 rgb(0 184 186 / 30%);
  background-color: #fff;
  border-radius: 50%;
  transform: rotate(85deg);
  display: block;
  width: 2rem;
  height: 2rem;
}

.head-one {
  background-color: #eff1f7;
  width: 100%;
}

.head-one tr th {
  padding: 0.75rem 0.938rem 0.75rem 0.938rem;
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #7e86a2;
  text-align: center;
}

.mobile-admin-user-thead {
  width: 100vw;
  background-color: #eff1f7;
}

.mobile-admin-user-thead > tr > th {
  /* padding: 0.75rem 0.938rem 0.75rem 0.938rem; */
  max-width: 250px;
  padding: 0.5rem;
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #7e86a2;
  text-align: center;
}

.mobile-admin-user-tbody > tr > td {
  /* max-width: 250px !important; */
}

.heading-five .heading-two-inner {
  margin-bottom: 2rem;
}

.body-one tr td {
  padding: 0.75rem 0.938rem 0.75rem 0.938rem;
  text-align: center;
  color: #39415c;
  font-family: "SCDream4";
  font-size: 0.813rem;
}

.body-one {
}

/* .body-one {
  height: 15vh;
  max-height: 100%;   */
/* } */

.body-one tr {
  /* margin-top: 3rem; */
}

.heading-five {
  background-color: transparent;
  box-shadow: none;
}

/* .body-one td div span:nth-child(1) {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 8px 0 0;
  background-color: #eff1f7;
  display: inline-block;
  border-radius: 50%;
} */

.body-one .td-1 {
  color: #00b8ba;
  font-family: "SCDream5";
}

.body-one .td-2 {
  color: #ffb500;
  font-family: "SCDream5";
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: flex-end;
  transition: 0.1s;
}

.td-2 svg {
  display: none;
}

.td-2:hover svg {
  display: block;
}

.body-one tr:hover {
  background-color: #fff;
  border-radius: 1rem;
}

.heading-six-inner svg:hover {
  box-shadow: 4px 2px 7px 0 rgb(0 0 0 / 10%);
  background-color: #fff;
}

.right-side-nav {
  z-index: 11;
  position: fixed;
  top: 0.2rem;
  right: 0.5rem;
  bottom: 0;
  width: 22rem;
  height: 68rem;
  border-radius: 1.25rem;
  background-color: #eff1f7;
  padding: 1rem;
}

.center {
  position: relative;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.coin-charge.active {
  background-color: #39415c;
}

.coin-charge.active .coin-charge-col h3 {
  color: #fff;
}

.head-one tr th:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.head-one tr th:last-child {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.body-one tr td:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.body-one tr td:last-child {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.header-col-1 .calendar {
  width: 9rem;
  height: 8rem;
}

.form-h6 {
  font-size: 1.125rem;
  color: #151c2c;
  font-family: "SCDream6";
  margin: 0;
}

.form-inner {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.form-inner label {
  font-size: 0.85rem;
  color: #596380;
  font-family: "SCDream5";
  padding-top: 1.7rem;
}

.form-inner input {
  padding: 0.563rem 0.625rem 0.563rem 0.8rem;
  border-radius: 0.625rem;
  margin: 0.625rem 0 0;
  background-color: transparent;
  border: 1px solid #e4e6ed;
}

.form-inner input::placeholder {
  font: 0.675rem;
  font-family: "SCDream4";
  color: #39415c;
}

.form-row {
  padding-left: 1rem;
  margin-top: 3rem;
}

.form-h6-one span {
  color: #00b8ba;
  font-size: 0.875rem;
  font-family: "SCDream5";
  padding-left: 0.75rem;
}

.form-row-one {
  padding: 1.25rem 0.5rem 1.25rem 0.5rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  background-color: #fff;
  margin-top: 3rem;
}

.form-row-one .form-inner input::placeholder {
  color: #7e86a2;
}

.form-label {
  margin-bottom: 0 !important;
}

.form-inner-1 {
  position: relative;
}

.card-span {
  display: flex;
  position: absolute;
  top: 4.4rem;
  right: 1rem;
  cursor: pointer;
}

.card-1 {
  width: 24px;
  height: 24px;
  display: block;
  background-color: #d62828;
  border-radius: 50%;
  margin-right: -0.75rem;
  position: relative;
  z-index: 1;
}

.card-2 {
  width: 24px;
  height: 24px;
  display: block;
  background-color: #ffe200;
  border-radius: 50%;
}

.form-inner-1 input {
  padding: 0.563rem 4.625rem 0.563rem 1.25rem;
}

.form-row-two {
  margin-top: 1.75rem;
}

.toogle-in[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  background: #c6c6c6;
  outline: none;
  transition: 0.7s;
  padding: 2px;
  width: 38px;
  height: 20px;
  border-radius: 32px;
}

.toogle-in[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 4.5px;
  left: 5px;
  transform: scale(1.1);
  transition: 0.5s;
  width: 12px;
  height: 12px;
  background: #fcfcfc;
  box-shadow:
    0px 2px 4px rgb(0 0 0 / 20%),
    inset 0px 2px 2px #ffffff,
    inset 0px -1px 1px rgb(0 0 0 / 10%);
  border-radius: 24px;
}

.toogle-in:checked[type="checkbox"] {
  background: #00b8ba;
}

.toogle-in:checked[type="checkbox"]:before {
  left: 21px;
}

.accordion-button {
  font-size: 0.875rem;
  color: #39415c;
  font-family: "SCDream5";
  background-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: #39415c;
  background-color: transparent;
}

.accordion-body {
  color: #596380;
  font-size: 0.813rem;
  font-family: "SCDream4";
}

.accordion-item:last-of-type {
  border-radius: 0.625rem;
}

.accordion-button:not(.collapsed)::after {
  background-image: url(../images/ic_select_close_20.png);
  transform: rotate(0deg);
}

.accordion-button::after {
  background-image: url(../images/ic_select_close_20.png);
  transform: rotate(-180deg);
}

.cancel-btn {
  border: 1px solid #e4e6ed;
  background-color: transparent;
  border-radius: 0.625rem;
  font-size: 0.93rem;
  font-family: "SCDream5";
  width: 14rem;
  height: 3rem;
  padding: 0.5rem;
  max-width: 100%;
  max-height: 100%;
  color: #7e86a2;
  transition: 0.4s;
}

.save-btn {
  background-color: #00b8ba;
  color: #fff;
  box-shadow: 0 3px 7px 0 rgba(0, 184, 186, 0.3);
  border: 1px solid transparent;
}

.cancel-btn:hover {
  background-color: #eff1f7;
}

.save-btn:hover {
  background-color: #00cbc0;
}

.payment-sec h4 {
  font-size: 1.25rem;
}

.payment-inner {
  padding: 1.5rem;
  width: 13rem;
  height: 11rem;
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #e4e6ed;
  max-width: 100%;
}

.payment-inner h4 {
  font-size: 2.5rem;
  padding-top: 2.65rem;
  font-family: "SCDream6";
}

.payment-inner h6 {
  margin: 0;
  font-family: "SCDream6";
  color: #39415c;
  font-size: 0.85rem;
}

.payment-inner:hover h6 {
  color: #ffffff;
}

.paylog-table-header {
  margin-bottom: 2rem;
}

.history-col {
  margin-top: 2rem;
  padding-left: 2rem;
}

.history-col-payinfo {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 2rem;
}

.history-col-inner {
  padding-top: 0;
  padding-left: 2rem;
  padding-bottom: 1.25rem;
}

.history-col-inner:hover {
  background-color: transparent;
  border-radius: 0;
}
.center {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-col-inner h5 {
  color: #00b8ba;
  font-size: 0.938rem;
  font-family: "SCDream5";
  margin: 0;
  padding-left: 5rem;
}

.plan-col {
  margin-top: 1rem;
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  overflow: hidden;
}

.plan-col-inner {
  background-color: #fff;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.plan-col-inner h6 {
  font-size: 1.5rem;
  font-family: "SCDream6";
  color: #151c2c;
}

.plan-col-inner p {
  margin: 0;
  font-size: 0.813rem;
  font-family: "SCDream4";
  color: #7e86a2;
}

.plan-col-inner p span {
  color: #00b8ba;
}

.radio-free-sec {
  background-color: #fcfcff;
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  padding: 3rem 2rem;
}

.radio-free-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e4e6ed;
  padding-bottom: 1.5rem;
}

.radio-free-col h6 {
  margin: 0;
  font-family: "SCDream5";
  color: #39415c;
}

.radio-free-inner .form-check-label {
  font-size: 0.875rem;
  color: #000;
  font-family: "SCDream4";
  padding-left: 0.75rem;
}

.radio-free-inner .form-check-input[type="checkbox"] {
  border-radius: 50%;
}

.textOrange {
  color: #ff8957;
}

.radio-free-inner .form-check-input {
  width: 16px;
  height: 16px;
}

.radio-free-inner .form-check-input:checked {
  background-color: #00b8ba;
  border-color: #e5e8eb;
}

.radio-free-inner .form-check {
  display: flex;
  align-items: center;
}

.borderNone {
  border: none;
}

.radio-free-inner .form-check-input:focus {
  border-color: #00b8ba;
  outline: 0;
  box-shadow: none;
}

.paddingZero {
  padding: 0 !important;
}

.radio-free-sec p {
  color: #39415c;
  padding-top: 1.25rem;
  font-size: 0.813rem;
  font-family: "SCDream5";
  margin-bottom: 0.5rem;
}

.under-radio-account {
  background-color: #fff;
  padding: 2rem 2rem;
}

.under-radio-content p {
  color: #39415c;
  font-size: 0.813rem;
  font-family: "SCDream5";
}

.under-radio-content-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  border-bottom: 1px solid #e4e6ed;
  padding-bottom: 2rem;
}

.under-radio-content-inner h5 {
  font-size: 0.875rem;
  color: #39415c;
  font-family: "SCDream5";
}

.under-radio-content-inner h6 {
  font-family: "SCDream5";
  color: #39415c;
}

.under-radio-content-inner-1 h6 {
  color: #00b8ba;
}

.under-radio-content-inner-1 h5 {
  color: #596380;
}

.under-radio-content-inner-1 {
  border-bottom: 0;
}

.make-payment .save-btn {
  width: 24rem;
  height: 3rem;
}

.plan-col-2 {
  border-radius: 0;
  border-left: 1px solid #e4e6ed;
  border-right: 1px solid #e4e6ed;
}

.radio-free-sec-2 {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
}

.radio-free-col-2 {
  border: 0;
  padding-bottom: 0.65rem;
}

.under-radio-content-inner-2 {
  padding-bottom: 1.2rem;
  margin-top: 1rem;
}

.under-radio-content-inner-2 .form-select {
  width: 9.5rem;
  height: 3rem;
  background-color: transparent;
  border-radius: 0.625rem;
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #39415c;
  max-width: 100%;
}

.plan-col-3 {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  border: 0;
}

.coin-charge {
  padding: 1.5rem 1rem 1rem 1.5rem;
  border-radius: 0.938rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  background-color: #fff;
  margin-top: 1.5rem;
  border: 2px solid transparent;
  transition: 0.3s;
  min-height: 10rem;
}

.coin-charge-col h3 {
  font-size: 1.77rem;
  font-family: "SCDream6";
  color: #39415c;
}

.coin-charge-col h6 {
  font-size: 0.938rem;
  font-family: "SCDream5";
  color: #00b8ba;
  margin: 0;
}

.coin-charge-inner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: -0.5rem;
}

.coin-charge-inner p {
  margin: 0;
  font-size: 0.75rem;
  color: #7e86a2;
  font-family: "SCDream5";
}

.coin-charge-inner img {
  width: 4rem;
}

.coin-charge:hover {
  border: 2px solid #e4e6ed;
  cursor: pointer;
}

.coin-charge-col h3 {
  display: flex;
  align-items: center;
}

.coin-charge-col h3 span {
  font-family: "SCDream6";
  font-size: 0.68rem;
  color: #fff;
  padding: 5px 5px 5px;
  border-radius: 6px;
  margin-left: 0.5rem;
  background-color: #ff8957;
}

.coin-charge-col h6 del {
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #a7b0c7;
  padding-left: 0.5rem;
}

.coin-info {
  padding: 1.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e4e6ed;
  margin-top: 1rem;
}

.coin-info h6 {
  font-family: "SCDream6";
  font-size: 0.938rem;
  color: #596380;
}

.coin-info p {
  font-family: "SCDream4";
  font-size: 0.938rem;
  margin: 0;
  color: #7e86a2;
}

.detail-sec span {
  color: #c9cedb;
  padding-right: 0.4rem;
}

.payment-detail {
  background-color: #fff;
  overflow: hidden;
  padding: 1.125rem;
  border-radius: 1rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.date-input {
  padding: 0.5rem 0.625rem 0.5rem 0.938rem;
  border-radius: 0.625rem;
  border: solid 1px #e4e6ed;
  position: relative;

  z-index: 2;
  background-color: transparent;
}

@media (max-width: 450px) {
  .date-input {
    width: 11rem;
    height: 3rem;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
}

.state-selection .form-select {
  border-radius: 0.625rem;
  border: solid 1px #e4e6ed;
  width: 11rem;
  max-width: 100%;
  padding: 0.5rem 0.625rem 0.5rem 0.938rem;
}

@media (max-width: 450px) {
  .mobile-flex-col {
    flex-direction: column !important;
  }

  .moblie-d-flex {
    display: flex;
  }

  .mobile-maxheight-productInfo {
    max-height: 6rem !important;
    overflow: hidden;
    /* text-overflow: ellipsis;
    white-space: nowrap; */
  }
}

.total-table-col .heading-five {
  padding: 0;
}

.total-table-col .heading-two-inner {
  margin-bottom: 1rem;
}

/* .total-table-col .body-one tr td:nth-child(4) {
  color: #00b8ba;
  font-family: "SCDream5";
}

.total-table-col .body-one tr td:nth-child(6) {
  font-family: "SCDream6";
} */

/* .total-table-col .body-one tr .com-pay {
  color: #d62828;
} */

.textRed {
  color: #d62828 !important;
}

.textGreen {
  color: #00b8ba !important;
}

.total-table-col .body-one tr td {
  padding: 0;
}

.tableP {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  padding: 0.95rem 0.938rem 0.75rem 0.938rem;
  margin: 0;
  margin-top: 0.6rem;
  height: 3rem;
}

.total-table-col .body-one tr:hover {
  background-color: transparent;
}

.tablePL {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.tablePR {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.search-title {
  width: 100%;
}

.search-title input {
  border-radius: 0.625rem;
  border: solid 1px #e4e6ed;
  max-width: 100%;
  padding: 0.5rem 0.625rem 0.5rem 0.938rem;
  width: 100%;
}

.search-title input::placeholder {
  font-family: "SCDream4";
  font-size: 0.875rem;
  color: #7e86a2;
}

.total-table-col .head-one tr th {
  padding: 0.95rem 0.938rem 0.75rem 0.938rem;
  height: 3rem;
}

.neutral {
  background-color: #00b8ba;
  border-radius: 50%;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
}

.pagination-nav svg {
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.pagination-nav a {
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #c9cedb;
  transition: 0.3s;
}

.pagination-nav a:hover {
  color: #1f2534;
}

.pagination-nav svg:hover {
  box-shadow: 0 2px 8px 0 rgba(0, 184, 186, 0.3);
}

.product-collect-nav .nav-link {
  margin-bottom: 0;
  font-family: "SCDream6";
}

.product-collect-nav .nav-link {
  font-size: 1rem;
  color: #7e86a2;
  transition: 0.3s;
  padding: 0rem 2rem;
}

.product-collect-nav .nav-link:hover {
  font-size: 1.25rem;
  color: #1f2534;
}

.product-collect-nav .selectedNavItem {
  font-size: 1.25rem;
  color: #1f2534;
}

.product-collect-nav ul li:hover span {
  width: 5px;
  height: 5px;
  margin: 5px 2px 2px 2px;
  background-color: #00b8ba;
  border-radius: 50%;
  display: block;
}

.nav-item {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tabs-col-btn .nav-link {
  color: #7e86a2;
  font-family: "SCDream5";
  font-size: 0.875rem;
  padding: 0.75rem 2.25rem;
  border: 0;
}

.tabs-col-btn .nav-link.active {
  font-family: "SCDream6";
  color: #1f2534;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.product-label-1 input::placeholder {
  color: #a7b0c7;
}

.hr2 {
  width: 103.25%;
  margin-left: -1.25rem;
  margin-top: 1.75rem;
}

.reset-btn {
  background-color: transparent;
  box-shadow: none;
  border: solid 1px #e4e6ed;
  color: #7e86a2;
}

.reset-btn:hover {
  background-color: #eff1f7;
}

.selected-num {
  padding: 1.35rem 0.25rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  background-color: #fff;
  align-items: center;
  margin-top: 2.25rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}

.selected-num h4 {
  font-family: "SCDream6";
  font-size: 1.125rem;
  margin: 0;
  color: #151c2c;
}

.total-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.first-product-tab {
  background-color: #fff;
  padding: 1.125rem;
  border-radius: 1.25rem;
}

.total-block-inner {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.total-block-inner h4 {
  font-family: "SCDream6";
  font-size: 1.125rem;
  margin: 0;
  color: #151c2c;
}

.form-group {
  display: block;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: "";
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: 1.18188px solid rgba(209, 213, 219, 0.7);
  border-radius: 4px;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  left: 40%;
  width: 5px;
  height: 11px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-group input:checked + label:before {
  background-color: #00b8ba !important;
}

.total-select {
  display: flex;
  align-items: center;
  position: relative;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.total-select svg {
  position: absolute;
  left: 0.5rem;
}

.total-select .form-select {
  width: 9rem;
  background-color: transparent;
  padding-left: 2.75rem;
  color: #39415c;
  font-family: "SCDream5";
  font-size: 0.875rem;
  z-index: 1;
  position: relative;
  height: 3rem;
}

.product-col {
  padding: 0.75rem;
  border-radius: 15px;
  border: solid 1px #e4e6ed;
  background-color: #f8f9fd;
  transition: 0.3s;
}

.product-col h4 {
  font-family: "SCDream5";
  font-size: 0.75rem;
  padding-top: 1rem;
}

.product-col-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-col-inner h6 {
  font-family: "SCDream4";
  font-size: 0.8rem;
  color: #7e86a2;
}

.product-col-inner h5 {
  font-family: "SCDream5";
  font-size: 0.8rem;
  color: #00b8ba;
}

.short-btn {
  height: 2.7rem;
  margin-top: 0.5rem;
  width: 16rem;
}

.product-col:hover {
  background-color: #fff;
}

.custom-col-product {
  width: 15.9rem;
  max-width: 100%;
  margin-top: 1.5rem;
}

.product-col:hover .short-btn {
  background-color: #00b8ba;
  color: #fff;
}

.hover-check-pro {
  position: relative;
  z-index: 9;
  background-color: #989eb5;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  display: none;
}

.hover-check-pro .form-group {
  position: absolute;
  top: 0.125rem;
  left: 0.45rem;
}

.custom-col-product:hover .hover-check-pro {
  display: block;
}

.group-pro-1 {
  padding-right: 0;
  width: 20%;
  max-width: 100%;
}

.group-pro-2 {
  width: 40%;
}

.group-pro-3 {
  width: 60%;
}

.period-btn {
  justify-content: space-around !important;
}

.toogle-in-1[type="checkbox"] {
  width: 33px;
  height: 17px;
}

.toogle-in-1[type="checkbox"]:before {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
}

.toogle-in-1:checked[type="checkbox"]:before {
  left: 20px;
}

.payment-detail-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex-wrap: wrap;
  justify-content: center !important;
  gap: 1rem !important;
  /* background-color: white;
  border-radius: 1.25rem; */
}

.row-or-col-btn {
  border: 1px solid #e4e6ed;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.row-or-col-btn svg {
  padding: 0.5rem;
  display: inline-block;
  width: 2.7rem;
  height: 2.7rem;
  transition: 0.4s;
}

.overflowX {
  overflow-x: auto;
}

.wordKeep {
  word-break: keep-all;
}

.oneLine {
  white-space: nowrap;
}

.row-or-col-btn svg g rect {
  fill: #a7b0c7 !important;
  stroke: #a7b0c7 !important;
}

.row-or-col-btn svg g path {
  fill: #a7b0c7 !important;
  stroke: #a7b0c7 !important;
}

.row-or-col-btn svg:hover {
  background-color: #00b8ba;
}

.madebutton .selectedSvg {
  background-color: #00b8ba;
}

.madebutton .selectedSvg g rect {
  fill: #fff !important;
  stroke: #fff !important;
}

.madebutton .selectedSvg g path {
  fill: #fff !important;
  stroke: #fff !important;
}

.row-or-col-btn svg:hover g rect {
  fill: #fff !important;
  stroke: #fff !important;
}

.row-or-col-btn svg:hover g path {
  fill: #fff !important;
  stroke: #fff !important;
}

.del-btn {
  color: #d62828;
  font-family: "SCDream6";
  width: 7.75rem;
  height: 2.9rem;
}

.del-btn:hover {
  color: #fff;
  background-color: #d62828;
}

.del-btn:hover svg path {
  stroke: #fff !important;
}

.sale-reg-btn {
  color: #00b8ba;
}

.sale-reg-btn:hover {
  background-color: #00b8ba;
}

.name-set-btn {
  width: 7.75rem;
  height: 2.9rem;
  color: #39415c;
}

.collect-market .collect-inner-1 {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  padding: 0.75rem;
  margin: 0;
  margin-top: 0.5rem;
  min-height: 12rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 170px;
  overflow-y: auto;
}

.collect-market .div-collect {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.collect-market .input-collect {
  display: flex;
  align-items: flex-start;
}

.collect-market .input-collect-inner input {
  width: 40rem;
  height: 2.8rem;
  padding: 0.5rem 4.5rem 0.5rem 1rem;
  border-radius: 10px;
  border: solid 1px #e4e6ed;
  background-color: #f8f9fd;
  max-width: 100%;
}

.collect-market .input-collect-inner input::placeholder {
  font-family: "SCDream4";
}

.collect-market .input-collect-inner {
  position: relative;
}

.collect-market .input-collect-btn {
  right: 0.25rem;
  position: absolute;
  top: 0.25rem;
}

.correction-btn {
  width: 3.75rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  background-color: #c9cedb;
  justify-content: center;
  box-shadow: none;
}

.correction-btn-2 {
  background-color: #00b8ba;
}

.coupan-text {
  margin-top: 1rem;
}

.coupan-text h6 {
  font-family: "SCDream5";
  font-size: 0.75rem;
  margin: 0;
  color: #7e86a2;
}

.collect-market .coupan-text span {
  background-color: #a7b0c7 !important;
  width: 4px !important;
  height: 4px !important;
  margin: 0 6px !important;
}

.coupan-text h5 {
  font-family: "SCDream5";
  font-size: 0.75rem;
  margin: 0;
  color: #39415c;
}

.coupan-text h4 {
  font-family: "SCDream4";
  font-size: 0.75rem;
  margin: 0;
  color: #7e86a2;
  text-align: start;
  margin-top: 0.25rem;
}

.sale-reg-btn:hover .svg-stroke1 {
  stroke: #00b8ba !important;
}

.del-btn:hover .svg-stroke2 {
  stroke: #d62828 !important;
}

.basic-btn {
  width: 6rem;
  height: 2.75rem;
}

.collect-market .coupan-text .copy-1 {
  width: 12px !important;
  height: 12px !important;
  margin: 0 0 0 5px !important;
  padding: 4px !important;
  background-color: transparent !important;
  border: 2px solid #d0d2dc !important;
  border-radius: 0.15rem !important;
  cursor: pointer;
}

.uploadThead .copy-1 {
  width: 12px !important;
  height: 12px !important;
  margin: 0 0 0 5px !important;
  padding: 4px !important;
  background-color: transparent !important;
  border: 2px solid #d0d2dc !important;
  border-radius: 0.15rem !important;
  cursor: pointer;
}

.uploadThead .copy-2 {
  margin-left: -0.55rem !important;
  margin-top: -0.4rem !important;
  border-bottom: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}

.collect-market .coupan-text .copy-2 {
  margin-left: -0.55rem !important;
  margin-top: -0.4rem !important;
  border-bottom: none !important;
  border-left: none !important;
  border-radius: 0 !important;
}

.original-price-inner h6 {
  font-family: "SCDream4";
  font-size: 0.75rem;
  color: #39415c;
  padding-bottom: 1rem;
}

.original-price-inner h5 {
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #39415c;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.tag-1 {
  color: #00b8ba;
  background-color: transparent;
  border: 1px solid #00b8ba;
  padding: 0px 6px;
  border-radius: 4px;
}

.tag-3 {
  color: #ea5856;
  background-color: transparent;
  border: 1px solid #ea5856;
  padding: 0px 6px;
  border-radius: 4px;
}

.div-collect-1 {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.collect-market .form-group input:checked + label:after {
  top: 4px;
}

.not-set h4 {
  font-family: "SCDream6";
  font-size: 0.813rem;
  color: #d62828;
}

.collect-market .collect-inner-2 span {
  background-color: #7e86a2 !important;
}

.p-name input::placeholder {
  font-family: "SCDream5";
  color: #39415c;
  font-size: 0.875rem;
}

.width-devide {
  width: 13.2%;
  padding-right: 0;
}

.width-devide-1 {
  width: 20%;
  padding-right: 0;
}

.view-count p {
  font-family: "SCDream5";
  font-size: 0.875rem;
  margin: 0;
  color: #39415c;
  padding-top: 0.2rem;
}

.view-count-sec .form-control {
  width: 10rem;
  max-width: 100%;
}

.play-btn {
  color: #ffb500;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem;
}

.font-bold {
  font-weight: 700;
}

.mr-2 {
  margin-right: 1rem;
}

.play-btn:hover {
  background-color: #ffb500;
}

.play-btn:hover .svg-stroke3 {
  stroke: #ffb500 !important;
}

.play-btn:hover svg g rect {
  fill: #fff !important;
}

.pause-btn:hover svg .svg-stroke4 {
  fill: #fff !important;
}

.collect-market-1 .original-price-inner h5 {
  font-family: "SCDream4" !important;
}

.total-view-col {
  width: 100%;
}

.original-td {
  width: 18%;
}

.modal-sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 4;
}

.upload-date h5 {
  font-size: 0.692rem;
}

.form-select:focus {
  outline: 0;
  box-shadow: 0 0 0 0.1rem #00b8ba;
}

.form-control:focus {
  border-color: #00b8ba;
  box-shadow: 0 0 0 0.1rem #00b8ba;
}

.width-select-1 {
  width: 9.5rem;
  max-width: 100%;
}

.unfolded {
  display: none;
}

.product-manage-1 {
  margin-top: 0rem !important;
}

.new-order-manage .width-select-1 {
  width: 13rem;
}

.new-order-manage .save-btn {
  width: 10.3rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  gap: 0.5rem;
}

.save-excel-col {
  margin-top: 0;
  padding: 0;
}

.save-excel-col .form-group input:checked + label:after {
  top: 4px;
}

.save-excel-inner tr th {
  border-right: 1px solid #e4e6ed;
}

.save-excel-inner tr th:nth-child(1) {
  width: 4%;
}

.save-excel-inner tr th:nth-child(6) {
  width: 16%;
}

.save-excel-inner tr th:nth-child(7) {
  width: 13%;
}

.save-excel-inner tr th:nth-child(2) {
  background-color: #deffff;
  color: #00b8ba;
}

.save-excel-inner tr th:nth-child(3) {
  background-color: #deffff;
  color: #00b8ba;
}

.save-excel-inner tr th:nth-child(4) {
  background-color: #deffff;
  color: #00b8ba;
}

.save-excel-inner tr th:nth-child(5) {
  background-color: #deffff;
  color: #00b8ba;
}

.save-excel-inner tr th:last-child {
  border: 0;
}

.save-excel-inner-1 tr td {
  border-bottom: 1px solid #e4e6ed;
  border-right: 1px solid #e4e6ed;
  font-family: "SCDream4";
  font-size: 0.813rem;
  color: #596380;
  background-color: #fff;
  border-radius: 0 !important;
}

.save-excel-inner-1 tr td:nth-child(2) {
  font-family: "SCDream5";
  color: #39415c;
}

.save-excel-inner-1 tr td:nth-child(3) {
  font-family: "SCDream5";
  color: #39415c;
}

.save-excel-inner-1 tr td:nth-child(4) {
  font-family: "SCDream5";
  color: #39415c;
}

.save-excel-inner-1 tr td:nth-child(5) {
  font-family: "SCDream5";
  color: #39415c;
}

.save-excel-inner-1 tr td:last-child {
  border-right: 0;
}

.save-excel-inner tr th:last-child {
  border-radius: 0 !important;
}

.save-excel-inner tr {
  border-bottom: 8px solid #f8f9fd;
}

.order-manage-5 {
  padding: 0;
  padding-top: 5rem;
}

.order-manage-5-inner {
  overflow-x: scroll;
  min-width: 100%;
  width: 100%;
}

.order-manage-5-inner-1 {
  min-width: 125rem;
  max-width: initial;
}

.order-manage-5-inner-1 .save-excel-inner tr th:nth-child(1) {
  width: 3%;
}

.order-manage-5-inner-1 .save-excel-inner tr th:nth-child(6) {
  width: 12%;
}

.order-manage-5-inner-1 .save-excel-inner tr th:nth-child(7) {
  width: 10%;
}

.order-manage-5-inner {
  border-radius: 0;
}

.cs-management-col .save-excel-inner tr th:nth-child(1) {
  width: 11%;
}

.cs-management-col .save-excel-inner tr th:nth-child(4) {
  width: 14%;
}

.cs-management-col .save-excel-inner tr th:nth-child(6) {
  width: initial;
}

.cs-management-col .save-excel-inner tr th:nth-child(7) {
  width: initial;
}

.cs-management-col .save-excel-inner tr th:nth-child(2) {
  background-color: #eff1f7;
  color: #7e86a2;
}

.cs-management-col .save-excel-inner tr th:nth-child(4) {
  background-color: #eff1f7;
  color: #7e86a2;
}

.cs-management-col .save-excel-inner tr th:nth-child(5) {
  background-color: #eff1f7;
  color: #7e86a2;
}

.order-manage-6 {
  padding: 0;
  padding-top: 5rem;
}

.order-manage-6-inner {
  overflow-x: scroll;
  min-width: 100%;
  width: 100%;
}

.order-manage-6-inner {
  border-radius: 0;
}

.order-manage-6-inner-1 {
  min-width: 125rem;
  max-width: initial;
}

.order-manage-6-inner-1 .save-excel-inner tr th:nth-child(1) {
  width: 8%;
}

.order-manage-6-inner-1 .save-excel-inner tr th:nth-child(4) {
  width: 10%;
}

.market-name-col {
  margin: 0;
}

.market-name-col .body-one tr td:nth-child(4) {
  color: #39415c;
  font-family: "SCDream4";
  font-size: 0.813rem;
}

.market-name-col .body-one tr td:nth-child(6) {
  font-family: "SCDream4";
}

.short-cut-p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
}

.cut-btn {
  border-radius: 8px;
  border: 1px solid #e4e6ed;
  background-color: #f8f9fd;
  padding: 0.45rem;
  transition: 0.3s;
}

.cut-btn:hover {
  background-color: #eff1f7;
}

.resizeNone {
  resize: none;
}

.smart-btn {
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  padding: 0.95rem 0.5rem;
  min-height: 2.9rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "SCDream5";
  font-size: 0.8rem;
  color: #39415c;
  margin-top: 1rem;
}

.active-smart-tick {
  margin-right: 2rem;
  width: 1rem;
  height: 1rem;
}

.acc-id {
  padding-right: 0.5rem;
}

.license-inner {
  margin-top: 1rem;
  width: 37.5%;
  max-width: 100%;
}

.apply-btn {
  height: 2.9rem;
}

.license-inner input::placeholder {
  color: #39415c;
}

.top-image-col {
  padding: 1.25rem;
  border-radius: 1.25rem;
  box-shadow: 0 5px 10px 0 #eff1f7;
  background-color: #fff;
  margin-top: 3rem;
}

.top-image-col h3 {
  font-family: "SCDream6";
  font-size: 1.125rem;
  color: #151c2c;
}

.filename-btn {
  height: 2.9rem;
  gap: 1rem;
  justify-content: flex-start;
}

.findfile-btn {
  background-color: #39415c;
  box-shadow: none;
}

.findfile-btn:hover {
  background-color: #fff;
  border: 1px solid #39415c;
  color: #39415c;
}

.user-head-col .user-th-1 {
  width: 5%;
}

.user-body-col td {
  font-size: 0.75rem !important;
}

.user-body-col td p {
  padding: 0.5rem !important;
  min-height: 5rem !important;
  height: initial !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.user-head-col tr th {
  font-size: 0.75rem;
  padding: 0.5rem !important;
}

.stop-td {
  color: #00b8ba !important;
}

.memory-btn {
  font-family: "SCDream5";
  font-size: 0.65rem;
  color: #39415c;
  border-radius: 6px;
  border: 1px solid #39415c;
  background-color: rgba(248, 249, 253, 0);
  padding: 0.65rem 0.45rem;
  line-height: 1;
  transition: 0.4s;
}

.memory-btn:hover {
  color: #fff;
  background-color: #39415c;
}

@media (max-width: 450px) {
  .mobile-width {
    width: 8rem !important;
  }

  .total-block-inner {
    display: none;
  }

  .mobile-modal-sticky {
  }

  .mobile-modal-body {
    /* width: 100vw; */
  }

  .mobile-order-info {
    flex-direction: column;
  }

  .mobile-order-info > div {
    width: 100% !important;
  }

  .mobile-width100 {
    width: 100% !important;
  }
}

.pause-btn1 {
  color: #d62828;
  border-color: #d62828;
}

.pause-btn1:hover {
  color: #fff;
  background-color: #d62828;
}

.correct-btn {
  border: 1px solid #e4e6ed;
}

.correct-btn:hover {
  background-color: #eff1f7;
  color: #39415c;
}

.write-new-sec {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
  align-items: center;
}

.category-maping-col .nav-link.active {
  background-color: #eff1f7;
  padding-left: 3rem;
  padding-right: 3rem;
}

.category-maping-col .first-product-tab {
  background-color: #eff1f7;
}

.confirm-fail {
  border: 1px solid #c9cedb;
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0.35rem;
}

.confirm-fail svg {
  display: inline-block;
  width: 2.25rem;
}

.confirm-fail a:first-child {
  border-right: 1px solid #c9cedb;
}

.code-div input {
  width: 6.25rem;
  height: 2.75rem;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #fff;
  max-width: 100%;
  border: 1px solid transparent;
  font-family: "SCDream4";
  font-size: 0.813rem;
  color: #7e86a2;
}

.code-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.category-inner-1 {
  min-width: 56rem;
  max-width: initial;
}

.code-div input:nth-child(2) {
  width: 9.25rem;
}

.code-div input:nth-child(3) {
  width: 9.25rem;
}

.code-div input:nth-child(4) {
  width: 9.25rem;
}

.code-div input:nth-child(5) {
  width: 9.25rem;
}

.decor-body tr td:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.maxwidth920 {
  width: 920px !important;
}

.decor-body tr td {
  text-align: center;
  color: #39415c;
  font-family: "SCDream4";
  font-size: 0.813rem;
}

.decor-div {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.decor-div-1 {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  padding: 0.25rem;
  margin: 0;
  margin-top: 0.5rem;
  min-height: 2.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.decor-div-2 {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.decor-body p {
  margin: 0;
}

.decor-body svg {
  height: 1.5rem;
}

.decor-body .form-group input:checked + label:after {
  top: 4px;
}

.furniture-col-inner {
  overflow-x: scroll;
  min-width: 100%;
  width: 100%;
}

.furniture-col-inner-table {
  min-width: 43rem;
  max-width: initial;
}

.smart-store tr td:nth-child(4) {
  color: #39415c !important;
  font-family: "SCDream4" !important;
}

.del-sel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #d62828;
  gap: 0.5rem;
  width: 12.5rem;
  height: 3.125rem;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #f8f9fd;
  transition: 0.3s;
}

.del-sel-btn:hover {
  border: 1px solid #d62828;
}

.smart-one {
  width: 100%;
}

.category-input input:nth-child(2) {
  width: 20rem;
}

.smart-one h4 {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #7e86a2;
  margin: 0;
}

.g-market {
  overflow-x: hidden;
}

.crystal-btn {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.crystal-span1 {
  width: 1px;
  height: 20px;
  background-color: #c9cedb;
}

.crystal-span2 {
  color: #00b8ba;
}

.crystal-span3 {
  color: #7e86a2;
}

.open-market-col {
  overflow-x: hidden;
}

.open-market-col-inner {
  min-width: initial;
}

.decor-div-2 .correction-btn-2 {
  border-radius: 6px;
}

.market-div .decor-div-1 {
  min-height: 3.5rem;
}

.street-eleven input:nth-child(2) {
  width: 6.25rem;
}

.street-eleven input:nth-child(3) {
  width: 6.25rem;
}

.street-eleven input:nth-child(4) {
  width: 6.25rem;
}

.street-eleven {
  gap: 0.5rem;
}

.street-eleven-col {
  overflow-x: hidden;
}

.street-eleven-col-inner {
  min-width: initial;
}

.interior-col {
  overflow-x: hidden;
}

.interior-col-inner {
  min-width: initial;
}

.interior-body tr td {
  font-size: 0.725rem;
}

/* all-modals */

.modal-dialog1 {
  height: 100%;
  z-index: 30;
  margin: 0 auto;
}

.modal-dialog2 {
  max-width: 800px;
  margin: 20rem auto;
  z-index: 30;
}

.modal-header {
  border-bottom: 0;
  padding: 2rem 2rem 1rem 2rem;
}

.modal-title {
  font-family: "SCDream6";
  font-size: 1.4rem;
  color: #191f28;
}

.modal-content {
  border-radius: 1.25rem;
}

.modal-body {
  background-color: #fff;
  padding: 0 2rem 1rem 2rem;
}

.product-collection-modal-1 .product-collection-modal-inner h6 {
  margin: 0;
  font-family: "SCDream5";
  font-size: 1.125rem;
  color: #191f28;
}

.o-btn {
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #d62828;
  width: 11.75rem;
  height: 2.5rem;
  border-radius: 6px;
  border: solid 1px #d62828;
  background-color: rgba(248, 249, 253, 0);
}

.g-btn {
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #39415c;
  width: 8.125rem;
  height: 2.5rem;
  border-radius: 6px;
  border: solid 1px #39415c;
  background-color: rgba(248, 249, 253, 0);
}

.product-collection-modal-1 .decor-div-1 {
  justify-content: flex-start;
  padding-left: 0rem;
}

.product-collection-modal-1 .decor-body tr td {
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #191f28;
  width: 25%;
}

.product-collection-modal-1 .market-div .decor-div-1 {
  min-height: 3rem;
}

.line-product {
  width: 4px;
  height: 34px;
  margin: 0 16px 0 0;
  background-color: #39415c;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.line-product-1 {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 8px 0 0;
  background-color: #7e86a2;
  display: inline-block;
  border-radius: 50%;
}

.three-selected {
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #00b8ba;
  border-radius: 6px;
  border: solid 1px #00b8ba;
  display: inline-block;
  margin-left: 1rem;
  padding: 0.25rem;
}

.pricing-modal .modal-body {
  padding-top: 0;
}

.pricing-input1 {
  background-color: #f8f9fd !important;
}

.pricing-modal .total-table-col .body-one tr td p {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-modal .price-state {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  margin: 0;
  margin-top: 0.6rem;
  min-height: 4rem;
  padding: 0.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-pr-3 {
  padding-right: 3rem !important;
}

.pricing-modal .state-selection .form-select {
  width: 5rem;
  padding: 0.5rem;
}

.price-one .pricing-input1 {
  background-color: #fff !important;
  padding: 0.5rem;
  width: 5rem;
  border-radius: 0.625rem;
}

.percentage {
  color: #39415c;
  font-family: "SCDream5";
  font-size: 0.75rem;
  margin: 0;
}

.price-one td:nth-child(2) {
  width: 45%;
}

.pricing-input2 {
  visibility: hidden;
}

.textStart {
  text-align: start;
}

.select-tag-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 10rem;
  height: 3rem;
  color: #39415c;
}

.attributes1 p {
  margin: 0;
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #39415c;
  margin-top: 0.4rem;
}

.attributes1 {
  margin-bottom: 0.75rem;
}

.option-image1 {
  width: 100% !important;
  font-size: 0.75rem;
}

.top-col-span span {
  padding-left: 4.65rem;
  padding-right: 4.65rem;
  color: #39415c;
}

.imges-line-nine1x {
  flex-wrap: wrap;
}

.detail-text-page {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #39415c;
  margin-bottom: 0.5rem;
}

.forbidden-btn {
  width: 9.5rem;
  color: #39415c;
  max-width: 100%;
}

.function-text {
  font-family: "SCDream6";
  font-size: 1.125rem;
  color: #191f28;
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}

.apply-btn1 {
  width: 6.25rem;
  height: 2.9rem;
}

.next-prev-svg svg {
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 6px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  display: inline-block;
}

.number-character input {
  padding-right: 4.5rem;
}

.number-character {
  position: relative;
}

.next-prev-svg {
  display: flex;
  width: 60px;
  position: absolute;
  top: 28px;
  right: 15px;
}

.duplicate1 input {
  border-color: #7e86a2;
  text-align: center;
  margin-bottom: 1.5rem;
}

.enter-product-name .body-one tr td:nth-child(1) {
  width: 15%;
}

.enter-product-name .body-one tr td:nth-child(2) {
  width: 0%;
}

.enter-product-name .body-one tr td:nth-child(3) {
  width: 70%;
}

.enter-product-name .body-one .price-state input {
  width: 100%;
}

.enter-product-name .body-one .price-state input::placeholder {
  color: #39415c;
  font-size: 0.875rem;
  font-family: "SCDream5";
}

.enter-product-name .body-one .b-table-l span {
  color: #7e86a2;
  padding-left: 0.35rem;
}

.dodge-btn {
  width: 8.75rem;
  height: 3rem;
  border-radius: 0.625rem;
}

.set-word-col .body-one .collect-inner-1 {
  min-height: 3rem;
}

.set-word-col .body-one tr td p {
  height: 3rem !important;
}

.change-of-word {
  position: relative;
}

.change-of-word-inner {
  position: absolute;
  left: 18.35rem;
  top: 0.6rem;
}

.change-of-word-inner1 .body-one tr td:nth-child(4) {
  color: #39415c;
  font-family: "SCDream4";
  font-size: 0.813rem;
}

.change-of-word-inner1 .body-one tr td:nth-child(3) {
  width: 25%;
}

.change-of-word-inner1 .body-one tr td:nth-child(1) {
  width: 10%;
}

.coupang-text1 {
  color: #596380;
  font-family: "SCDream5";
  font-size: 0.938rem;
}

.global-select {
  color: #7e86a2 !important;
}

.complete1 .radio-free-inner .form-check-label {
  color: #00b8ba;
  font-family: "SCDream5";
}

.id-enter {
  margin-top: 2rem;
}

.id-enter label {
  font-family: "SCDream4";
  padding-bottom: 0;
  padding-top: 0.5rem;
}

.progress-circle {
  padding: 0;
}

.progress-circle label {
  color: #7e86a2 !important;
}

.smart-store-tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  font-family: "SCDream6";
  color: #1f2534;
  border: none;
  border-bottom: 2px solid #00b8ba;
}

.smart-store-tabs .nav-link {
  font-family: "SCDream5";
  color: #7e86a2;
}

/* .smart-store-tabs .nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #00b8ba;
  isolation: isolate;
} */

.work-number-table-tab {
  border: 1px solid #e4e6ed;
  border-radius: 10px;
  padding: 1.5rem;
  margin-top: 2rem;
}

.work-number-table-tab .table thead tr th {
  border: 0;
  color: #596380;
  font-family: "SCDream4";
  font-size: 0.715rem;
}

.work-number-table-tab .table tbody tr td {
  border: 0;
  color: #39415c;
  font-family: "SCDream5";
  font-size: 0.715rem;
}

/* .work-number-table-tab .table tbody tr td:nth-child(3) {
  color: #d62828;
} */

.red-error-text {
  padding: 0.875rem;
  border-radius: 6px;
  border: 1px solid #d62828;
}

.red-error-text p {
  margin: 0;
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #d62828;
}

.green-success-text {
  padding: 0.875rem;
  border-radius: 6px;
  border: 1px solid #00b8ba;
}

.green-success-text p {
  margin: 0;
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #00b8ba;
}

.market-unique-number1 {
  margin-bottom: 0;
}

.market-unique-number2 {
  margin-top: 1rem;
}

.market-unique-number2 .work-number-table-tab .table tbody tr td:nth-child(3) {
  color: #00b8ba;
}

.market-unique-number2 .red-error-text {
  border: 1px solid #00b8ba;
}

.market-unique-number2 .red-error-text p {
  color: #00b8ba;
}

.slick-digit-1 {
  position: relative;
}

.slick-digit-1 span {
  width: 7.875rem;
  height: 7.875rem;
  border-radius: 10px;
  background-color: #eff1f7;
  display: block;
}

.slick-digit-1 svg {
  position: absolute;
  top: 0;
  right: 0;
}

.dustbin {
  margin-top: 1.9rem;
}

.dustbin svg {
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  display: block;
  width: 2.85rem;
  height: 2.85rem;
  padding: 5px;
}

.add-tag-btn {
  width: 100%;
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #00b8ba;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  height: 3rem;
  transition: 0.4s;
}

.add-tag-btn:hover {
  border: 1px solid #00b8ba;
}

.add-option-btn {
  color: #00b8ba;
}

.option1-btn {
  font-family: "SCDream6";
  font-size: 1.125rem;
  color: #fff;
  width: 7.875rem;
  height: 2.5rem;
  border-radius: 20px;
  background-color: #39415c;
  padding: 0.125rem;
  transition: 0.3s;
}

.option1-btn:hover {
  color: #39415c;
  background-color: #ffffff;
}

.option-one-inner {
  border: 1px solid #e4e6ed;
  padding: 1rem;
  border-radius: 10px;
}

.option-name {
  margin: 0;
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #39415c;
}

.click-img p {
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  margin-left: 0.25rem;
}

.click-img svg {
  width: 45px;
  height: 45px;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  display: block;
}

.click-img svg:hover {
  background-color: #f8f9fd;
}

.color-name-inner {
  position: relative;
}

.color-name-inner input {
  padding-right: 3.5rem;
}

.color-name-inner1 {
  position: absolute;
  top: 0;
  right: 0.75rem;
}

.color-name-inner1 svg {
  width: 45px;
  height: 45px;
  padding: 6px;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  display: block;
}

.color-name-inner-col {
  top: 2rem;
}

.apply-as {
  margin-top: -1.5rem;
}

.apply-as .add-option-btn {
  width: 11.875rem;
  background-color: #fff;
  max-width: 100%;
}

.stop-selling1 input {
  width: 100%;
  height: 3rem;
  padding: 7px 60px 7px 15px;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  max-width: 100%;
}

.stop-selling1 input::placeholder {
  font-size: 0.875rem;
  font-family: "SCDream5";
  color: #39415c;
}

.stop-selling1 {
  position: relative;
}

.correct-btn1 {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-family: "SCDream5";
  font-size: 0.875rem;
  color: #fff;
  height: 2rem;
  border-radius: 6px;
  background-color: #a7b0c7;
  border: 1px solid transparent;
  max-width: 100%;
}

.correct-btn2 {
  background-color: #39415c;
}

.stop-selling2 .form-select {
  width: 100% !important;
  height: 3rem !important;
  background-image: url(../images/ic_select_open_20.png);
  background-position: 58%;
  background-size: 20px 20px;
  padding-right: 4rem !important;
}

.color-name-picker .collect-inner-1 {
  min-height: 4rem !important;
  border: 0;
  border-radius: 0;
  padding: 0.25rem;
}

.color-name-picker1 tr th {
  color: #191f28;
  padding: 0.75rem !important;
}

.color-name-picker .pick-input input {
  width: 100%;
  padding: 7px 7px 7px 15px;
  max-width: 100%;
  color: #39415c;
}

.pick-input1 {
  width: 8.5rem;
}

.btn-color-in input::placeholder {
  color: #7e86a2;
}

.btn-color-in {
  color: #7e86a2;
}

.double-div1 {
  min-height: 20rem;
  background-color: #f8f9fd;
}

.double-div2 {
  min-height: 20rem;
  background-color: #e4e6ed;
  max-width: 20rem;
  margin: auto;
}

.div-digit-1 {
  position: relative;
  border: 2px solid transparent;
  transition: 0.1s;
}

.div-digit-1:hover {
  border: 2px solid #00b8ba;
  border-radius: 10px;
  cursor: pointer;
}

.div-digit-1 span {
  min-height: 4.5rem;
  border-radius: 10px;
  background-color: #eff1f7;
  display: block;
  max-width: 100%;
}

.div-digit-1 svg {
  position: absolute;
  top: -4px;
  right: -1px;
  width: 1.125rem;
}

.imges-line-nine {
  flex: 0 0 auto;
  width: 11.1111111%;
  padding-right: 0;
  padding-left: 0.75rem;
  padding-top: 1.25rem;
}

.ui-img {
  min-height: 50vh;
}

.plus-img-inner {
  flex: 0 0 auto;
  width: 19%;
}

.plus-img1 {
  min-height: 7.5rem;
  border-radius: 10px;
  border: 2px dashed #e4e6ed;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}

.plus-img1:hover {
  background-color: #f8f9fd;
}

.editor-col-1 {
  min-height: 22rem;
  background-color: #a7b0c7;
}

.double-div3 {
  min-height: 11.25rem;
  background-color: #f8f9fd;
}

.double-div4 {
  min-height: 11.25rem;
  background-color: #a7b0c7;
  max-width: 12rem;
  margin: auto;
}

.applied-btn1 {
  width: 10.5rem;
  color: #d62828;
}

.applied-btn1:hover {
  background-color: #fff;
  border-color: #d62828;
}

.applied-btn2 {
  width: 10.5rem;
  color: #00b8ba;
}

.applied-btn2:hover {
  background-color: #fff;
  border-color: #00b8ba;
}

.left-right-svgs {
  margin-top: 0.5rem;
}

.left-right-svgs svg {
  padding: 2px;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  display: block;
  width: 100%;
  transition: 0.3s;
  min-height: 2.5rem;
}

.left-right-svgs svg:hover {
  background-color: #f8f9fd;
}

.duplicate-removal-btn {
  font-family: "SCDream5";
  font-size: 0.875rem;
  width: 100%;
  min-height: 3rem;
  border-radius: 10px;
  border: 1px solid #7e86a2;
  background-color: #fff;
  text-align: center;
  transition: 0.3s;
}

.duplicate-removal-btn:hover {
  border: 1px solid #7e86a2;
  background-color: #f8f9fd;
}

.being-sold-span {
  font-family: "SCDream5";
  font-size: 0.75rem;
  color: #00b8ba;
}

.change-type1 .form-select {
  width: 100% !important;
  padding-left: 1rem !important;
}

.change-price-in input::placeholder {
  color: #7e86a2;
}

.view-o-btn {
  border: 1px solid #00b8ba;
}

.ordering-col h3 {
  font-family: "SCDream5";
  font-size: 1.125rem;
  color: #39415c;
}

.ordering-col h6 {
  font-family: "SCDream5";
  font-size: 0.938rem;
  color: #39415c;
}

.ordering-col1 {
  padding: 1.875rem;
}

.order-market1 p {
  font-family: "SCDream4";
  font-size: 0.938rem;
  color: #39415c;
  margin-bottom: 0.5rem;
}

.hr3 {
  background-color: #e4e6ed;
  margin-top: 1.5rem;
}

.courier1 .form-select {
  width: 100% !important;
}

.courier2 input::placeholder {
  color: #7e86a2;
}

.inquiries-body1 .ordering-col h6 {
  font-family: "SCDream4";
  font-size: 0.85rem;
}

.inquiries-body1 .ordering-col1 {
  padding: 1.75rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.enter-answer input::placeholder {
  font-family: "SCDream4";
  font-size: 0.9rem;
  color: #7e86a2;
}

.enter-answer .option-one-inner {
  width: 100%;
  text-align: start;
  padding-bottom: 10rem;
}

.please-select1 .form-select {
  width: 100% !important;
  padding-left: 1rem !important;
  color: #7e86a2 !important;
}

.please-select1 {
  position: relative;
}

.select-svg {
  position: absolute;
  top: 2.5rem;
  right: 4rem;
  cursor: pointer;
}

.check-btn-modal {
  width: 9rem;
}

.check-btn-modal:hover {
  background-color: #c9cedb;
}

.shipping-location1 .form-select {
  width: 100% !important;
}

.expiration-label label {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #596380;
  padding-bottom: 0.5rem;
}

.enter-detailed1 {
  padding: 1.25rem 1.25rem 4rem;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
}

.enter-detailed1 h6 {
  font-family: "SCDream4";
  font-size: 0.875rem;
  color: #39415c;
}

.enter-detailed1 span {
  min-height: 14.375rem;
  margin: 1.25rem 0;
  border-radius: 10px;
  background-color: #e4e6ed;
  display: block;
}

.keyword-competition-rate1 .head-one tr th:first-child {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.keyword-competition-rate1 .head-one tr th:last-child {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border: 0;
}

.keyword-compete1 {
  border-bottom: 1px solid #e4e6ed;
}

.keyword-competition-rate1 .head-one tr th {
  padding: 0.75rem;
}

.mob-key {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.keyword-competition-rate1 .head-one tr th {
  border-right: 1px solid #e4e6ed;
}

.keyword-compete1 th {
  border: 0 !important;
}

.keyword-compete1 th:first-child {
  border-top-left-radius: 0.625rem !important;
}

.keyword-compete1 th:last-child {
  border-top-right-radius: 0.625rem !important;
}

.keyword-compete2 th:first-child {
  border-bottom-left-radius: 0.625rem !important;
}

.keyword-compete2 th:last-child {
  border-bottom-right-radius: 0.625rem !important;
}

.keyword-compete2 th:nth-child(2) {
  border: 0 !important;
}

.keyword-compete2 th:nth-child(4) {
  border: 0 !important;
}

.keyword-compete2 th:nth-child(6) {
  border: 0 !important;
}

.keyword-compete2 th:nth-child(7) {
  border: 0 !important;
}

.keyword-compete-body tr td {
  padding: 0;
}

.keyword-compete-body tr td:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.keyword-compete-body tr td {
  text-align: center;
  color: #39415c !important;
  font-family: "SCDream4" !important;
  font-size: 0.813rem;
}

.selectedItem {
  background-color: #e0f3ff;
}

.keyword-compete-body tr td div {
  padding-left: 1rem;
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  margin: 0;
  margin-top: 0.6rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.keyword-compete-body tr td .mob-key1 {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.keyword-compete-body tr td .mob-key2 {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.mob-key1 svg {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.mob-key1 svg:hover {
  border: 1px solid #00b8ba;
}

.mob-key3 {
  border-right: 1px solid #e4e6ed;
}

.tags-selecter-text p {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #596380;
}

.tags-selecter-text p span {
  color: #00b8ba;
}

.desktop-nav-active .sidenav ul li a {
  width: 100%;
  height: 50px;
  text-align: left;
  margin-left: 0;
  justify-content: left;
}

.desktop-nav-active .sidebarBottom {
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.desktop-nav-active .sidebar-inner {
  padding-bottom: 0;
  border-bottom: 0;
  border-right: 0;
  padding-right: 0;
}

.desktop-nav-active .sidebarBottom {
  position: relative;
}

@media (max-width: 993px) {
  .desktop-nav-active .heroSec {
    padding-left: 0;
  }

  .contentContainer {
    padding-left: 0;
  }
}

.textCenter {
  text-align: center;
}

/* .desktop-nav-active .sidebarBottom:before {
  position: absolute;
  content: "";
  left: 50%;
  height: 30px;
  width: 1px;
  background-color: #f2ac00;
} */

.rounded-md {
  border-radius: 0.625rem;
}

.rounded-sm {
  border-radius: 10px;
}

.inline-block {
  display: inline-block;
}

.overflowY {
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: scroll;
}

.modalBg {
  overflow: unset !important;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 40;
  /* display: block; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.modalInner {
  position: relative;
  max-height: 90vh;
  overflow-y: scroll;
  background-color: #fff;
  z-index: 31;
  border-radius: 1.25rem;
}

.spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dateContainer {
  width: 50%;
  position: relative;
}

.calender {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
}

.padding200 {
  padding: 200px 0;
}

/* Firefox
input[type="number"] {
  -moz-appearance: textfield;
} */

.fr-second-toolbar {
  display: none !important;
}

.fr-wrapper {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.widthFull {
  width: 100%;
}

.widthHalf {
  width: 50%;
}

.margin0 {
  margin: 0;
}

.wideModal {
  width: 90vw;
}

.heightFull {
  height: 100%;
}

.spinner1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner1 svg {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.paymentGray {
  font-size: 1.25rem;
  color: #c9cedb;
}

.periodBox {
  overflow-x: hidden !important;
}

.unset {
  overflow-y: unset !important;
}

.stickyBgHeight {
  /* overflow: auto; */
  max-height: 100%;
  height: 100vh;
}

.textEnd {
  text-align: flex-end;
}

.table-sticky {
  position: sticky;
  top: 0;
  /* z-index: 3; */
  background-color: #eff1f7;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d5d5d5;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.main-prepare {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000090;
  z-index: 3;
}

.main-prepare > p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 16rem;
  font-size: 24px;
  color: #fff;
  font-family: "SCDream7";
}

.information-window {
  position: absolute;
  top: 4.75rem;
  right: 3.45rem;
  padding: 1rem;
  z-index: 100;
  width: 440px;
  height: max-content;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 20px;
  border: 2px solid rgba(248, 249, 253, 1);
  box-shadow: 3px 2px 10px 1px rgba(0, 0, 0, 0.25);
  font-family: "SCDream5";
}

@media (max-width: 450px) {
  .information-window {
    width: 270px;
    padding: 1rem 1rem 0 1rem;
    right: 1.25rem;
    top: 2.75rem;
  }
}

.information-window div {
  font-size: 16px;
  line-height: 21px;
  color: rgba(255, 137, 87, 1);
  font-weight: 500;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  padding-bottom: 8px;
  padding-bottom: 8px;
}

.information-window p {
  margin-top: 10px;
  color: rgba(57, 65, 92, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
}

.information-window.open {
  animation: window-modal-open 1s ease-in-out;
}

.information-window.closed {
  animation: window-modal-closed 1s ease-in-out;
}

@keyframes window-modal-open {
  0% {
    transform: translateX(120%);
  }

  60% {
    transform: translateX(-5%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes window-modal-closed {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(200%);
  }
}

.global-tooltip {
  position: absolute;
  top: -2rem;
  background-color: #39415c;
  padding: 2px 10px;
  border-radius: 5px;
}

.global-tooltip > p {
  color: #ffffff;
  font-size: 14px;
  font-family: "SCDream6";
  margin: 0;
}

.agree-guard-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #f8f9fd90;
  z-index: 99;
}

.agree-window {
  background-color: #fff;
  width: 450px;
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.customCheckbox {
  cursor: pointer;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid rgba(217, 217, 217, 1);
}

.customCheckbox.checked {
  display: flex;
  border: 1.5px solid rgba(68, 192, 186, 1);
  background-image: url(../images/checked.png);
  background-repeat: no-repeat;
  background-position: 1px 2px;
}

.agree-title {
  font-size: 24px;
  font-family: "SCDream6";
}

.agree-all {
  font-size: 20px;
  font-family: "SCDream5";
}

.agree-select-text {
  font-size: 16px;
  font-family: "SCDream5";
}

.marketing-text {
  font-size: 14px;
  font-family: "SCDream6";
  padding-left: 2rem;
}

.agree-submit-button {
  background-color: rgba(68, 192, 186, 1);
  border-radius: 10px;
  padding: 10px, 30px;
  font-size: 20px;
  font-family: "SCDream6";
  text-align: center;
}

.alignCenter {
  text-align: center;
}

.mobile-modal-button {
  padding: 0;
  width: 90px;
  height: 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 7px;
}

.mobile-modal-button-white {
  background-color: #fff;
  border: 1px solid #d9d9d9;
}

.mobile-modal-button-green {
  background-color: #00b8ba;
  color: #fff;
  border: none;
}

.fr-element ul {
  list-style: disc;
}

.fr-element ol {
  list-style: decimal;
}

.fr-bordered {
  border: solid 5px #ccc;
}

.fr-shadow {
  box-shadow: 10px 10px 5px 0px #cccccc;
}

.fr-rounded {
  border-radius: 10px;
}

.input-searching-icon {
  position: absolute;
  right: 0;
}

.tag-placeholder::placeholder {
  font-size: 12px !important;
}

.heigth90 {
  height: 90vh;
}
