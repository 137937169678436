.mappingTable input[type="checkbox"]:focus {
  outline-color: #00b8ba !important;
  outline-style: solid;
  outline-width: 2px !important;
}

.total-table-col .userListTable {
  height: 50vh;
  overflow-y: auto;
  overflow-x: auto;
}

.userListTable thead th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #eff1f7;
}
.pagingButtonWrapper {
  padding-top: 20px;
}

.notice-container-height {
  height: 90vh;
}

.noContents {
  text-align: center;
  white-space: nowrap;
  color: #39415c;
}

.noContents > td > p {
  padding: 200px 0 !important;
}

.tableGrayText {
  color: #39415c !important;
  font-family: "SCDream4" !important;
}

.tableRedText {
  color: #d62828;
  font-family: "SCDream5" !important;
}

.tableGreenText {
  color: #00b8ba !important;
  font-family: "SCDream6" !important;
}

.modalInTable {
  text-align: left;
}

.modalSecondHeader {
  padding-left: 32px;
}

.editModalExpireDate {
  width: 100%;
  height: 2.8rem;
}

.date-input-1 {
  width: 30%;
  position: relative;
}

.date-input-custom {
  width: 100%;
  position: relative;
}

.date-input-custom svg {
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}

.date-input-1 svg {
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}

.editorWrapper {
  min-height: 300px;
  padding: 2rem 0 2rem 0;
}

.ck .ck-editor__main div {
  height: 300px;
}

.noticeItem:hover {
  cursor: pointer;
}

.noticeDetailModal {
  text-align: initial;
}

.col-12 .noticeContents span {
  width: 100% !important;
}

.mappingTable {
  width: 100%;
  height: 670px;
  overflow-x: auto;
}
.mappingTable::-webkit-scrollbar {
  display: none;
}

.tableheadFirstDiv {
  height: 100%;
}

.mappingTableTab th {
  text-align: center;
}

.mappingTableTab th:last-child {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.mappingTableTab th:first-child {
  border-bottom-left-radius: 1.25rem;
}

.mappingTableTab th {
  background-color: #eff1f7;
  padding: 1rem;
}

.mappingTableTab th input {
  padding: 0.5rem;
  width: 6rem;
  height: 2.75rem;
  border-radius: 8px;
  border: 1px solid transparent;
}

.mappingTableTab th label {
  height: 2.75rem;
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

/* .tab-content {
  position: sticky;
  top: 0;
  background-color: #eff1f7;
  z-index: 1;
} */

.mapping > td > p {
  padding: 0.5rem 0;
}

.mappingTableDiv {
  width: 100%;
  height: 350px;
  overflow-x: auto;
  overflow-y: auto;
}
.tableTitle {
  background-color: #eff1f7;
  border: 1px;
}

.tabButton {
  min-width: 5.5rem;
}

.mappingTable tbody > tr p {
  margin-bottom: 0;
  min-width: 7rem;
  text-align: center;
}

.mappingTable tbody > tr td {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
}

.mappingTable tbody > tr td:first-child {
  border-left: 1px solid #e4e6ed;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.mappingTable tbody > tr td:last-child {
  border-right: 1px solid #e4e6ed;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.mappingTable tbody > tr {
  height: 45px;
  text-align: center;
  padding: 0.25rem 1rem;
  color: #757575;
}

.coupangTable thead {
  border-spacing: 0;
}

.coupangTable {
  width: 100%;
  position: relative;
  top: -10px;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.fixedSizeWrapper {
  width: 100%;
  height: 100%;
}

.smartStoreItem td p:first-child {
  padding-top: 0.2rem;
  padding-bottom: 0;
  font-size: 12px;
}

.smartStoreItem td p:nth-child(2) {
  padding-top: 0.1rem;
  padding-bottom: 0.2rem;
  font-size: 10px;
}

.selectedMappingItem {
  background-color: #e0f3ff;
}

.mappingHead {
  max-height: 200px;
  overflow-y: auto;
}

.mappingHead > table > thead > tr {
  position: sticky;
  top: 0;
}

.mappingTableTab {
  position: sticky;
  top: 0;
}
.buttonBox2 {
  height: 2.75rem;
  display: flex;
}

.inputCheckBox > label {
  position: relative;
  top: 0.75rem;
  height: 2.75rem;
}

.mappingTableTab .mappingCategoryInput {
  width: 80%;
}

.manageTableDiv {
  overflow-y: auto;
  width: 100%;
  max-height: 80vh;
}

.borderBottom {
  border-bottom: 0;
}

.manageTab {
  width: 50%;
}

.padding {
  padding: 0 10px;
}

.manageMarketCategoryHead > thead > tr {
  color: #7e86a2;
}

.manageMarketCategoryHead > thead > tr > th > label::before,
.manageMarketCategoryHead > thead > tr > th > label::after {
  margin-top: 0.7rem;
}

.buttonHeight {
  height: 3.125rem;
  gap: 0.5rem;
}

.mappingTable2 {
  width: 50%;
  height: 350px;
  overflow-x: auto;
  overflow-y: auto;
}

.correction-btn1 {
  width: 3.75rem;
  height: 2.25rem;
  background-color: #c9cedb;
  box-shadow: none;
}

.correction-btn-2 {
  background-color: #00b8ba;
}

.textCenter {
  color: #7e86a2;
  text-align: center;
}

.paddingLarge {
  padding-top: 30%;
}

.mappingTableFull {
  width: 100%;
  height: 80vh;
  overflow-x: auto;
  overflow-y: auto;
}

.detailCode {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #7e86a2;
  text-align: center;
}

.coupangTable {
  font-family: "SCDream4";
}

.coupangCategoryRefresh {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.standard {
  font-size: 18px;
  font-family: "SCDream8";
}

.userMemoButton {
  padding: 0.3rem 1rem;
  border-radius: 10px;
  color: #fff;
  background: #00b8ba;
}

.careItem > td > div {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.careFunctionBox {
  width: 250px;
  gap: 10px;
}

.userIdBox {
  width: 200px;
}

.userIdBox p {
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fqa-froala-container .fr-element {
  height: 600px;
  overflow-y: auto;
}

.admin-helper-contents-container {
  border-radius: 1rem;
  height: 700px;
  background-color: #fff;
  padding: 1rem;
  overflow-y: auto;
}

.mappedNewButton {
  top: -90px;
  left: 0;
  z-index: 10;
}

.mappedNewButton2 {
  top: -90px;
  left: 100px;
  z-index: 10;
}

.admin-fqalist-container {
  max-height: 600px;
  overflow: auto;
}

.fr-popup.fr-active {
  z-index: 9999 !important;
}

.popupImg {
  width: 500px;
  height: 500px;
}

.pinnedTag {
  white-space: nowrap;
  padding: 1px 10px;
  color: #cc0000;
  border-radius: 5px;
  border: 2px solid #cc0000;
  height: 25px;
  vertical-align: middle;
}

.mappingTable tbody tr {
  margin-top: 10px;
}

.mappingTable tbody tr td div {
  padding: 0.25rem 1rem;
}
