.detailInfoBox {
  padding-top: 10px;
  overflow-y: unset;
  max-width: 20rem;
  text-align: center;
}

.alignBase {
  align-items: baseline !important;
}

.tooltipRel {
  position: relative !important;
  cursor: pointer;
}

.tagBox>span {
  max-height: 21px;
}

.uploadTooltip {
  z-index: 10;
  position: absolute !important;
  bottom: 50px;
  background-color: #000;
  padding: 0.2rem 1rem;
  opacity: 0.5;
  color: #fff;
  border-radius: 50px;
}

.priceSettingModalTr>td>div {
  height: 62.5px;

  padding: 1rem;
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
}

.priceSettingModalTr>td:first-child>div {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.priceSettingModalTr>td:last-child>div {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.settubgPriceSettingWay {
  width: 6rem;
}

.relKeyword {
  font-size: 11px;
}

.relKeyword>th {
  font-size: 11px;
}

.relKeyword>th>div {
  font-size: 11px;
}

.keyword-compete-body tr td .keywordComp {
  padding: 0;
  justify-content: center;
}

.keyWordSettingTable>thead>tr>th {
  border-spacing: 0;
}

.tagSettingTable {
  max-height: 350px;
  overflow-y: auto;
}

.tagSettingTable>table>tbody>tr>td>div {
  height: 45px;
}

.tagSettingTable>table>thead>tr>th {
  position: sticky;
}

.keywordItem>td {
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  margin-top: 7px;
  margin-left: 7px;
}

.detailPageSelectButton {
  padding: 1rem;
  width: 50%;
  text-align: center;
}

.deatilSelectContainer {
  display: flex;
  border-radius: 0.625rem;
  border: 1px solid #e4e6ed;
}

.deatilSelectContainer>button:first-child {
  border-bottom-left-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.deatilSelectContainer>button:last-child {
  border-bottom-right-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.divider {
  border-left: 0.7px solid #e4e6ed;
}

.addProductOption {
  max-width: 30%;
}

.tableMaxHeight {
  max-height: 500px;
}

.textRed {
  color: #d62828;
}

.selectBox {
  overflow-x: hidden;
  width: 100%;
  min-width: 30px;
  max-height: 220px;
  position: absolute;
  top: 2.4rem;
  z-index: 2;
  background-color: #fff;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  border: solid 1px #e4e6ed;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #00b8ba;
  overflow-y: auto;
}

.relative {
  position: relative;
}

.selectBoxItem {
  font-family: "SCDream5";
  font-size: 14px;
  padding: 0.5rem;
  margin: 0;
  color: #39415c;
}

.selectBoxXbutton {
  position: absolute;
  right: 2rem;
  top: 0.1rem;
}

.textSm {
  font-size: 12px;
}

.outline {
  outline: 0;
  box-shadow: 0 0 0 0.1rem #00b8ba;
}

.paddingZero {
  padding: 0;
}

.defaultInfoImg {
  width: 100%;
  height: 100%;
  border-radius: 0.625rem;
}

.imgWrapper {
  width: 7.625rem;
  height: 7.625rem;
}

.imgWrapper>img {
  width: 7.625rem;
  height: 7.625rem;
}

.representThumb {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  color: #00b8ba;
  font-family: "SCDream5";
}

.imgXIcon {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 2;
}

.imageInput {
  display: none;
}

.imageInputLabel {
  border: 2px dashed #39415c;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  width: 7.625rem;
  height: 7.625rem;
  border-radius: 0.625rem;
}

.imageInputLabel:hover {
  background-color: #eff1f7;
}

.defaultInfoText {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #596380;
  padding-bottom: 0.5rem;
  text-align: left;
}

.uploadLogWrapper {
  overflow-x: auto;
  padding: 1rem 1.5rem;
}

.cropperheight {
  height: 350px;
}

.thumbnailHeight {
  width: 140px;
  height: 140px;
}

.priceSettingTable tbody tr td>div {
  margin-top: 10px;
}

.optionModalSubtitle {
  font-family: "SCDream6";
  font-size: 1.125rem;
  color: #191f28;
  text-align: left;
}

.optionDiv1 {
  max-height: 35vh;
  overflow-y: scroll;
}

.optionDiv2 {
  max-height: 25vh;
  overflow-y: scroll;
}

.colorOptionMargin {
  margin-right: 20px;
}

.optionTable>tbody>tr>td>div>input {
  min-width: 100px;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  border: 1px solid #e4e6ed;
  background-color: #fff;
  padding: 7px 6px 7px 15px;
  color: #39415c;
  margin-right: 20px;
}

.option-img-name {
  width: 16.66666667%;
}

.option-value {
  width: 75%;
}

.plusOptionIconWrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0.8rem;
  width: 2.9rem;
  height: 2.9rem;
  border-radius: 10px;
  border-left: solid 1px #e4e6ed;
}

.optionThumbnail {
  width: 45px;
  height: 45px;
  border-radius: 10px;
}

.optionEditMain {
  width: 20rem;
  height: 20rem;
}

.optionSmallThumbnail {
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.optionImgDeleteButton {
  position: absolute;
  top: 5px;
  right: -6px;
}

.buttonBorder {
  border: 1px solid #e4e6ed;
  border-radius: 10px;
  padding: 0.3rem 1rem;
}

.wayButtonLeft {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  border-left: 1px solid #e4e6ed;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  padding: 0.5rem;
  width: 2.7rem;
  height: 2.7rem;
  transition: 0.4s;
}

.wayDivider {
  border-right: 1px solid #e4e6ed;
}

.wayButtonRight {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  border-right: 1px solid #e4e6ed;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem;
  width: 2.7rem;
  height: 2.7rem;
  transition: 0.4s;
}

.waySelected {
  background-color: #00b8ba;
}

.waySelected svg g rect {
  fill: #fff !important;
}

.waySelected>svg g path {
  fill: #fff !important;
}

.simpleUploadItem>td>div {
  min-height: 0 !important;
  height: 120px !important;
}

.simpleUploadItem>td:nth-child(3)>div {
  overflow: unset;
}

.itemImg {
  width: 5rem;
  height: 5rem;
}

.uploadItem>td:nth-child(4)>div {
  overflow: unset;
}

.simpleUploadItemInput {
  height: 2rem !important;
}

.simpleUploadItemButton {
  height: 1.6rem;
}

.simpleUploadItem>td:last-child>div .tagBox {
  height: 50px;
  overflow: auto;
}

.tagBox {
  height: 70px;
  overflow: scroll;
}

.copyTooltip {
  z-index: 10;
  position: absolute !important;
  bottom: 25px;
  background-color: #000;
  padding: 0.2rem 1rem;
  opacity: 0.5;
  color: #fff;
  border-radius: 50px;
}

.tagTooltip {
  z-index: 50;
  position: absolute !important;
  left: -120px;
  bottom: 0;
  background-color: #000;
  padding: 0.2rem 1rem;
  opacity: 0.5;
  color: #fff;
  border-radius: 50px;
}

.tagText {
  padding: 0.2rem;
}

.aTag:hover {
  color: #39415c;
}

.aTag {
  cursor: pointer;
  color: #39415c;
}

.buttonBoxSticky {
  position: sticky;
  top: -15px;
  z-index: 9;
  background-color: #f8f9fd;
}

@media (max-width: 450px) {
  .moblie-buttonbox {
    /* display: none !important; */

    .send-invoice {
      display: none;
    }

    .save-excel {
      display: none;
    }

    .total-select {
      display: none;
    }
  }

  .mobile-text-ellipsis {
    /* white-space: nowrap !important; */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 100% !important;
  }
}

.mobile-spinner {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
}

.stickyHeader {
  position: sticky;
  top: 5.8rem;
  z-index: 8;
  background-color: #eff1f7;
}

@media (max-width: 1037px) {
  .stickyHeader {
    position: sticky;
    top: 10rem;
  }
}

@media (max-width: 993px) {
  .stickyHeader {
    position: static;
  }
}

.exchangeTable>thead>tr>th {
  font-size: 12px;
  padding: 0.95rem 0.938rem 0.75rem 0.938rem;
  background-color: #eff1f7;
  color: #7e86a2;
}

.exchangeTable>thead>tr>th>div {
  display: flex;
  justify-content: center;
}

.exchangeTable>thead>tr>th:first-child {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.exchangeTable>thead>tr>th:last-child {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.exchangeTable>tbody>tr>td>div {
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  color: #39415c;
}

.exchangeTable>tbody>tr>td>div input {
  height: 2.5rem;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  padding-left: 0.8rem;
  font-family: "SCDream5";
  font-size: 13px !important;
}

.smallInfoText {
  font-size: 12px;
  color: #9d9fa4;
}

.priceSettingModalTr>td>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.priceSettingModalTr>td>div input {
  background-color: #fff !important;
  padding: 0.5rem;
  width: 4rem;
  border-radius: 0.625rem;
  height: 2.5rem;
}

.priceSettingModalTr>td>div select {
  height: 2.5rem;
  background-color: #fff !important;
  padding: 0.5rem;
  width: 4rem;
  border-radius: 0.625rem;
}

.noTag {
  height: 180px;
  color: #39415c;
}

.settingValueHeader {
  padding: 0.5rem;
  font-weight: 500;
  font-size: 14px;
  background-color: #f8f9fd;
  color: #596380;
  border-radius: 0.625rem;
}

.width3 {
  width: 3%;
}

.width5 {
  width: 5%;
}

.width7 {
  width: 7%;
}

.width10 {
  width: 10%;
}

.width15 {
  width: 15%;
}

.width20 {
  width: 20%;
}

.width23 {
  width: 23%;
}

.width25 {
  width: 25%;
}

.width30 {
  width: 30%;
}

.width35 {
  width: 35%;
}

.width40 {
  width: 40%;
}

.width45 {
  width: 45%;
}

.width55 {
  width: 55%;
}

.width60 {
  width: 60%;
}

.width65 {
  width: 65%;
}

.width70 {
  width: 70%;
}

.width75 {
  width: 75%;
}

.width80 {
  width: 80%;
}

.width85 {
  width: 85%;
}

.width90 {
  width: 90%;
}

.m-auto {
  margin: auto;
}

.detailSetting {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  color: #596380;
  font-family: "SCDream5";
  column-gap: 10px;
}

.detailSettingButtonBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
}

@media (max-width: 1330px) {
  .detailSetting {
    flex-direction: column;
    align-items: end;
  }

  .detailSettingButtonBox {
    flex-direction: row;
    column-gap: 20px;
    margin-top: 20px;
  }
}

.pl2 {
  padding-left: 1rem;
}

.smallSelect {
  padding-left: 0.5rem;
  width: 90px;
  height: 32px;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
}

.largeSelect {
  padding-left: 0.5rem;
  width: 100%;
  height: 32px;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
}

.gap8 {
  margin-right: 4rem;
}

.gap4 {
  margin-right: 2rem;
}

.froalaSelectBox {
  position: relative;
  top: 1px;
  left: 23px;
  overflow: hidden;
  text-align: center;
  display: flex;
  font-size: 14px;
  font-family: "SCDream5";
  color: #596380;
  width: 220px;
  height: 46px;
  border: 1px solid #e4e6ed;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.froalaSelectBox>div {
  height: 100%;
  border-left: 1px solid #e4e6ed;
}

.selectedStateBox {
  background-color: #00b8ba;
  color: #fff;
}

.defaultHeight {
  min-height: 475px;
}

.settingConfigBox {
  padding: 0 10px;
  width: 50%;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.settingConfigBox>div {
  padding: 0 20px;
}

.arrangSettingBox {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

@media (max-width: 993px) {
  .settingConfigBox {
    width: 100%;
  }
}

.textYellow {
  color: #ffb500;
}

.imgToolBox {
  display: flex;
  width: 100%;
}

.tui-image-editor-header-buttons {
  display: none;
}

.toastButtonBox {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  z-index: 2;
}

.toastCancelButton {
  box-sizing: border-box;
  border-radius: 100px;
  width: 120px;
  font-family: "SCDream5";
  font-size: 14px;
  height: 40px;
  background-color: #fff;
  color: #000;
}

.toastSaveButton {
  box-sizing: border-box;
  border-radius: 100px;
  width: 120px;
  font-family: "SCDream5";
  font-size: 14px;
  height: 40px;
  background-color: #ffb500;
  color: #fff;
}

.toastUnset {
  overflow-x: unset;
}

.tui-colorpicker-clearfix {
  display: flex;
  flex-wrap: wrap;
}

.tui-colorpicker-clearfix>li>input {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  color: transparent !important;
  font-size: 1px !important;
}

.tui-colorpicker-palette-preview {
  font-size: 0;
}

.center {
  top: 0;
  right: 0;
  transform: translate(0, 0);
}

.originalImg {
  width: 500px;
}

.transelateGetButton {
  border-radius: 0.625rem;
}

.resultDiv {
  height: 500px;
}

.squareMode {
  background-color: #00b8ba;
  color: white;
}

.madeToolBar {
  background-color: #fff;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  top: 0px;
  left: 100px;
  z-index: 10;
  padding-bottom: 1rem;
}

.toolButton {
  border: 1px solid #e4e6ed;
  border-radius: 1rem;
  padding: 5px 10px;
  background-color: white;
}

.possibleTranslatebutton {
  background-color: #ffb500;
  color: white;
  border: none;
}

.possbleEditorPlus {
  color: white !important;
  background-color: #00b8ba;
  border: none;
}

.textTomato {
  color: #ff8957;
}

.possiblePrev {
  color: #fff;
  background-color: #ff8957;
  border: none;
}

.descModalHeader {
  font-family: "SCDream6";
  font-size: 18px;
  background-color: #eff1f7;
  border-radius: 0.625rem;
  padding: 0.7rem;
}

.width47 {
  width: 47%;
}

.descListBox {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.mr-2 {
  margin-right: 10px;
}

.margintoplarge {
  margin-top: 10rem;
}

.productNameModalSubtitle {
  font-family: "SCDream5";
  color: #596380;
  font-size: 16px;
}

.apply-btn-modal {
  width: 9.5rem;
}

.font16 {
  font-size: 16px;
}

.newRedButton {
  width: 117px;
  height: 48px;
  color: #cc0000;
  border: 1px solid #cc0000;
  border-radius: 5px;
  background-color: #fff;
  font-family: "SCDream6";
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.newRedButton:hover {
  background-color: #cc0000;
  color: #fff;
}

.prohibitSearchInput {
  background-color: #fff;
  width: 82px;
  border-radius: 5px;
  border: none;
  padding: 0.2rem 0.5rem;
  font-family: "SCDream6";
}

.prohibitWordTableMinHeight {
  min-height: 400px;
}

.tag-setting-table-head {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #7e86a2;
  text-align: center;
}

.tag-modal-top-text {
  color: #7e86a2;
  font-family: "SCDream6";
  font-size: 14px;
}

.option-item-table td:nth-child(3) {
  width: 40%;
}

.tui-image-editor-container {
  border-radius: 20px;
}

@media (max-width: 450px) {
  .moblie-orderSearchboard {
    display: block !important;
  }
}

.moblie-orderSearchboard {}

.optionAddButton {
  width: 200px;
  height: 40px !important;
  border: 1px solid #e4e6ed;
  color: #00b8ba;
  text-align: center;
  border-radius: 0.625rem;
}

.optionAddButton:hover {
  background-color: #00b8ba;
  color: #fff;
}

.viewerContainer {
  border-radius: 1rem;
  /* background-color: #f8f9fd; */
}

.thumbIcon {
  text-align: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 0;
}

.thumbIcon:hover {
  border: 1px solid #00b8ba;
}

.noneIcon {
  width: 40px;
  height: 40px;
}

.thumbToolPlace {
  padding: 5px;
  height: 40px;
}

.thumbtooltip {
  background-color: #39415c;
  padding: 3px 10px;
  border-radius: 10px;
  color: #ffffff;
  font-size: 14px;
  font-family: "SCDream6";
}

.paddingRight0 {
  padding-right: 0;
}

.detail-modal {
  height: 90vh;
}

/* .froala-container {
  height: 68vh;
  overflow: auto;
  background-color: #fff;
} */

.z3 {
  z-index: 4;
}

.fr-quick-insert {
  left: 30px !important;
}

.table-bg {
  background-color: #eff1f7;
}

.price-setting-input {
  position: relative;
  height: 2.5rem;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  font-family: "SCDream5";
  font-size: 13px !important;
  text-align: center;
}

.price-set-check>input {
  display: none;
}

.price-set-check input:checked+label::before {
  background-color: #00b8ba;
  border: none;
}

.price-set-check label::before {
  content: "";
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: 1.18188px solid rgba(15, 17, 21, 0.7);
  border-radius: 7px;
}

.price-set-check input:checked+label::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border-radius: 1px;
}

.price-set-check2>input {
  display: none;
}

.price-set-check2 label::before {
  content: "";
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border: 1.18188px solid rgba(15, 17, 21, 0.7);
  border-radius: 7px;
}

.price-set-check2 input:checked+label::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 6px;
  height: 13px;
  border: solid #00b8ba;
  border-width: 0 3.3px 3.3px 0;
  transform: rotate(45deg);
  border-radius: 1px;
}

.expect-thumb {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.expect-table {
  max-height: 70vh;
  overflow-y: auto;
}

.border-grey {
  border: 1px solid #d9d9d9;
}

.recommend-search-btn {
  z-index: 1;
  top: 30px;
  right: 0;
}

.name-hover-img {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  position: absolute;
  float: inline-start;
  top: -320px;
  left: 100px;
}

.pl-3 {
  padding-left: 1.5rem;
}

.tag-list-box {
  max-height: 250px;
  overflow-y: auto;
}

.circle30Green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00b8ba;
}

.circle30Yellow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffb500;
}

.height45 {
  margin-top: 10px;
  height: 45px;
}

.border-left {
  border-bottom-left-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
}

.border-right {
  border-bottom-right-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.option-minus-border {
  width: 30px;
  height: 30px;
  border: 1px solid #eff1f7;
  border-radius: 10px;
}

.option-input-delete-btn {
  top: -1px;
  right: 6px;
}

.optionlist-table {
  max-height: 530px;
  overflow-y: auto;
}

.opacity5 {
  opacity: 0.5;
}

.option-group-height {
  height: 220px;
  overflow: auto;
}

.option-list-table-head {
  position: sticky;
  top: 0;
  background-color: #eff1f7 !important;
  z-index: 30;
}

.pr-2 {
  padding-right: 8px;
}

.pannelImg {
  border-radius: 10px;
  width: 100px;
  height: 100px;
}

.detail-panner-container {
  max-height: 560px;
  overflow-y: auto;
}

.allttam-studio-viewer {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  padding: 10px 0;
  height: 350px;
  overflow-y: auto;
}

.allttam-studio-img {
  height: 280px;
  background-size: cover;
}

.img-templates-container {
  border: 1px solid #d9d9d9;
  padding: 10px 0;
  border-radius: 10px;
}

.translate-result-container {
  height: 280px;
}

.img-pannel-xicon {
  top: -40px;
  right: 5px;
}

.button-grey-border {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.keyword-container {
  overflow-x: auto;
}

.bottom20 {
  bottom: 16px;
}

.sellingPointsItem {
  border: 5px solid red;
}

.batch-apply-btn {
  bottom: 3px;
  right: 3px;
  background-color: #c9cedb;
  color: #fff;
  width: 40px;
  border-radius: 10px;
}

.expect-thumb-big {
  width: 300px;
  height: 300px;
  top: 50px;
  left: -300px;
  z-index: 4;
  border-radius: 10px;
}

.option-thumb-big {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 40px;
  left: 50px;
  border-radius: 10px;
  z-index: 2;
}

.option-thumb-big2 {
  width: 300px;
  height: 300px;
  top: 40px;
  left: -300px;
  border-radius: 10px;
  z-index: 2;
}

.green-searching-icon {
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: #00bab8;
  border-radius: 50%;
}

.selected-bulk-img {
  border: 2px solid #00b8ba;
  box-sizing: border-box;
}