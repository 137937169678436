.noScroll {
  overflow: hidden !important;
}

.searchingTableFont {
  font-family: "SCDream5";
  font-size: 0.813rem;
  color: #596380;
  padding-bottom: 0.5rem;
}

.searchingFormHeight {
  height: 2.8rem;
}

.searchWaySelect {
  width: 100% !important;
}

.orderCollectDay {
  font-size: 10px;
}
.tableContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.tableContainer table,
.tableContainer table {
  width: 100%;
  border-collapse: collapse;
}

.tableContainer th,
.tableContainer td {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 1rem;
}

.tableContainer th {
  background-color: #f4f4f4;
  /* text-align: left; */
}

.tableContainer td > div {
  font-size: 1rem;
}

.tableItemInnerButton {
  color: #39415c;
  width: 5rem;
  height: 1rem;
  border: 1px solid #e4e6ed;
  padding: 0.5rem;
  text-align: center;
  border-radius: 10px;
}

.resultTableScroll {
  overflow-x: auto;
}
.orderInfoTable {
  max-width: 100%;
  width: 100%;
  overflow-x: scroll;
}

.orderInfoTable > thead > tr > th {
  background-color: #f7f7f7;
  padding: 1rem;
}

@media (max-width: 450px) {
  .orderInfoTable > thead > tr > th {
    background-color: #f7f7f7;
    padding: 0.2rem;
  }
  .orderInfoTable > tbody > tr > td > div {
    padding: 0rem !important;
  }
}

.orderInfoTable > thead > tr > th:first-child {
  border-top-left-radius: 0.625rem;
}

.orderInfoTable > thead > tr > th:last-child {
  border-top-right-radius: 0.625rem;
}

.orderInfoTable > tbody > tr > td {
  padding: 0;
}

.orderInfoTable > tbody > tr > td:first-child > div {
  border-left: 1px solid #e4e6ed;
}

.orderInfoTable > tbody > tr > td:last-child > div {
  border-right: 1px solid #e4e6ed;
}

.orderInfoTable > tbody > tr > td > div {
  border-bottom: 1px solid #e4e6ed;
  padding: 1rem;
}

.orderInfoTable > tbody > tr:first-child > td > div {
  height: 120px;
}

.orderInfoTable > tbody > tr:last-child > td > div {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.orderInfoPriceRight {
  font-size: 16px;
  text-align: right;
}

.orderInfoModalInput {
  font-size: 13px;
  width: 100%;
  height: 2.8rem;
  border-radius: 0.625rem;
  border: solid 1px #e4e6ed;
  padding: 0.5rem 0.625rem 0.5rem 0.938rem;
}
.orderInfoModalInput:focus {
  border-color: #00b8ba;
  outline: 0;
  box-shadow: none;
}

.ordererInfoTitle {
  width: 40%;
  font-size: 13px;
  margin: 0;
}

.ordererInfoTitle2 {
  width: 13%;
  font-size: 13px;
  margin: 0;
}

.pccButton {
  border-radius: 10px;
  padding: 0 2rem;
  background-color: #7e86a2;
  color: #fff;
}

.logTable {
  width: 100%;
}

.logTable > thead > tr > th {
  background-color: #f7f7f7;
  padding: 1rem;
}

.logTable > thead > tr > th:first-child {
  border-top-left-radius: 0.625rem;
}

.logTable > thead > tr > th:last-child {
  border-top-right-radius: 0.625rem;
}

.logTable > tbody > tr > td > div {
  padding: 1rem;
  font-size: 12px;
}

.tableItemInput {
  padding-left: 0.5rem;
  height: 1.75rem;
  color: #212529;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  background-color: #fff;
  margin-bottom: 3px;
}

.OrderInfotableItemInput {
  padding-left: 0.5rem;
  height: 1.5rem;
  color: #212529;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  background-color: #fff;
}

.OrderInfotableItemInputMobile {
  /* padding: 0rem 0.5rem; */
  padding-left: 0.5rem;
  height: 3.5rem;
  color: #212529;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  background-color: #fff;
}

.tableItemTextArea {
  padding: 0.5rem;
  height: 4rem;
  color: #212529;
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  background-color: #fff;
  margin-bottom: 3px;
  resize: none;
  margin-top: 1rem;
}

.tableItemInput:focus {
  border-color: #00b8ba;
  outline: 0;
  box-shadow: none;
}

.tableItemTextArea:focus {
  border-color: #00b8ba;
  outline: 0;
  box-shadow: none;
}

@media (max-width: 895px) {
  .payment-detail-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: end !important;
  }
}

.payment-detail-1 {
  max-width: 100%;

  overflow-x: auto;
}

.revenueModalLeft {
  width: 25%;
}

.revenueModalRight {
  width: 70%;
}
.overflowY-scroll {
  overflow-y: scroll !important;
}

.loginLog {
  border: 1px solid #e4e6ed;
  border-radius: 0.625rem;
  height: 8.8rem;
  padding: 0.3rem 0.8rem;
}
.loginLogComplete {
  padding-bottom: 0rem;
}

.loginLogText {
  color: #212529;
}

@media (max-width: 450px) {
  .loginLog {
    height: 26vh !important;
  }
  .pccButton {
    width: 6rem;
    height: 3rem;
    /* font-size: 0.7rem; */
    /* padding: 0 0.3rem; */
    padding-left: 0.6rem;

    padding-right: 0.3rem;
  }
}

.loginbutton {
  border: 1px solid #e4e6ed;
  border-radius: 10px;
  padding: 0 0.5rem;
  margin-left: 1rem;
}

.csTable > tbody > tr > td > div {
  margin-top: 1rem;
  height: 45px;
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
}

.csTable > tbody > tr > td:first-child > div {
  border-left: 1px solid #e4e6ed;
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.csTable > tbody > tr > td:last-child > div {
  border-right: 1px solid #e4e6ed;
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.orderInfoImg {
  max-width: none;
  width: 80px;
  height: 80px;
}

.logoRound {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.sticky2 {
  position: sticky;
  top: 10rem;
  z-index: 2;
}

.order-loginresult-loading-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-text {
  max-width: 100px; /* specify your desired width */
  overflow: hidden;
  text-overflow: ellipsis; /* optional, for adding "..." to clipped text */
  white-space: nowrap;
}

.overflow-auto {
  overflow: auto;
}
