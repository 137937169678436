.paymentCoinSelect:hover {
  cursor: pointer;
  background-color: #39415c;
}

.paymentCoinSelect:hover h3 {
  color: #fff;
}

.selectedCoin {
  background-color: #39415c;
}

.selectedCoin > h3 {
  background-color: #39415c;
}

.paymentGray:hover {
  color: #c9cedb;
}

.payResultTable {
  font-family: "SCDream4";
  width: 100%;
  margin-top: 3rem;
  background-color: #fff;
  border-radius: 1.25rem;
  padding: 2rem 1.5rem;
}
@media (max-width: 450px) {
  .moblie-payment-info {
    flex-direction: row;
    overflow-x: scroll;
  }

  .slick-slider {
    height: 70vh;
  }

  .slick-list {
    height: 100%;
  }
  .slick-dots {
    bottom: 4rem !important;
  }

  .slick-list img {
    height: 100% !important;
  }
  .plan-text {
    width: 20rem;
    padding: 0.3rem;
  }
  .moblie-pay {
    width: 100% !important;
    flex-direction: column;
    gap: 1rem;
  }
  .moblie-hidden {
    display: none !important;
  }
}

.resultTitle {
  font-size: 25px;
}

.receiptButton {
  width: 16rem;
  height: 2.5rem;
  background-color: #39415c;
  border-radius: 0.625rem;
  color: #fff;
}

.toHomeButton {
  width: 16rem;
  height: 2.5rem;
  background-color: #00b8ba;
  border-radius: 0.625rem;
  color: #fff;
}

.codeButton {
  width: 16rem;
  background-color: #00b8ba;
  border-radius: 0.625rem;
  color: #fff;
  font-size: 20px;
}

@media (max-width: 450px) {
  .payLogTable {
    min-width: 100% !important;
  }
  .moblie-pay-heading {
    border-radius: 0;
  }
  .moblie-pay-heading > div {
    min-width: 100% !important;
  }
  .payLogTable > tbody > tr > td > p {
    font-size: 0.7rem !important;
  }

  .payLogTable > tbody > tr > td > p {
    height: 3rem;
  }
}

.payLogTable > tbody > tr > td > p {
  border-top: 1px solid #e4e6ed;
  border-bottom: 1px solid #e4e6ed;
  margin: 10px 0 0 0;
  padding: 0.5rem;
}

.payLogTable > tbody > tr > td:first-child > p {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  border-left: 1px solid #e4e6ed;
}

.payLogTable > tbody > tr > td:last-child > p {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  border-right: 1px solid #e4e6ed;
}

.translateLogButtonBox {
  display: flex;
  font-family: "SCDream3";
}

.imgLogButton {
  border-radius: 0.625rem;
  border: 1px solid #ffb500;
}

.imgLogButton:focus {
  border: 1px solid #ffb500 !important;
}

.imgLogButton:hover {
  border: none;
  background-color: #ffb500;
  color: #fff;
}

.textLogButton {
  border-radius: 0.625rem;
  border: 1px solid #00b8ba;
  color: #00b8ba;
}

.textLogButton:hover {
  background-color: #00b8ba;
  color: #fff;
}

.planSub {
  font-family: "SCDream6";
}

.payment-log {
  font-family: "SCDream5";
}

.pay-period-select {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customInput {
  width: 100%;
  height: 3rem;
  padding: 1rem;
  font-size: 20px;
  font-family: "SCDream5";
  border: 2px solid #e4e6ed;
  border-radius: 0.625rem;
}

.infoColorText {
  font-size: 28px;
  font-family: "SCDream8";
  width: 100%;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coinBox {
  background-color: #fff;
  position: relative;
  top: -25px;
  padding: 2rem 1rem 1rem 1rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.promoteBox {
  max-width: 290px;
  height: 90px;
  background-color: #fff;
  position: relative;
  top: -22px;
  padding: 0.2rem 1rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.planBox {
  width: 290px;
  height: 370px;
  background-color: #fff;
  position: relative;
  right: 0;
  top: -5px;
  padding: 1rem 1rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.planImg {
  width: 290px;
  height: 180px;
}

.discountBox {
  background-color: #ff8957;
  color: #fff;
  padding: 0.1rem 0.8rem;
  border-radius: 15px;
}

.vat {
  font-size: 8px;
  color: #596380;
}

.ribon {
  top: 7px;
  left: -7px;
}

.ribon2 {
  top: -10px;
  left: -10px;
}

.ml-7 {
  margin-left: 10px;
}

.starter-container {
  border: 3px solid #ffb500;
  border-radius: 50px;
  padding-left: 5.5rem;
}

.starter-inside-button {
  padding: 1.3rem 3rem;
}

.starter-inside-button-top {
  position: relative;
  left: 3px;
}

.starter-inside-button-top:hover {
  color: white;
  background-color: #ffb500;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
}

.starter-inside-button-bottom {
  position: relative;
  left: 3px;
  top: 3px;
}

.starter-inside-button-bottom:hover {
  color: white !important;
  background-color: #00b8ba;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  box-sizing: content-box;
}

.payment-starter[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  background: #ffb500;
  outline: none;
  transition: 0.7s;
  padding: 2px;
  width: 55px;
  height: 30px;
  border-radius: 32px;
}

.payment-starter[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 4.5px;
  left: 5px;
  transform: scale(1.1);
  transition: 0.5s;
  width: 20px;
  height: 20px;
  background: #fcfcfc;
  box-shadow:
    0px 2px 4px rgb(0 0 0 / 20%),
    inset 0px 2px 2px #ffffff,
    inset 0px -1px 1px rgb(0 0 0 / 10%);
  border-radius: 24px;
}

.payment-starter:checked[type="checkbox"] {
  background: #ffb500;
}

.payment-starter:checked[type="checkbox"]:before {
  left: 30px;
}

.offline-button {
  margin-right: 4rem;
}

.none-select {
  color: #a3aeae;
}

.starter-contents {
  border-radius: 50px;
  background-color: #fff;
  padding: 3rem 2rem;
}

.starter-check-button {
  padding: 0 10px;
  border: 1px solid #ffb500;
  color: #ffb500;
  border-radius: 20px;
}

.starter-check-button:focus {
  border: 1px solid #ffb500 !important;
}

.greyA {
  position: relative;
  top: 5px;
}

.payment-starter-mobile[type="checkbox"] {
  position: relative;
  -webkit-appearance: none;
  background: #ffb500;
  outline: none;
  transition: 0.7s;
  padding: 2px;
  width: 30px;
  height: 16px;
  border-radius: 32px;
}

.payment-starter-mobile[type="checkbox"]:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  transform: scale(1.1);
  transition: 0.5s;
  width: 10px;
  height: 10px;
  background: #fcfcfc;
  box-shadow:
    0px 2px 4px rgb(0 0 0 / 20%),
    inset 0px 2px 2px #ffffff,
    inset 0px -1px 1px rgb(0 0 0 / 10%);
  border-radius: 24px;
}

.payment-starter-mobile:checked[type="checkbox"] {
  background: #ffb500;
}

.payment-starter-mobile:checked[type="checkbox"]:before {
  left: 17px;
}

.starter-mobile {
  position: relative;
  margin: auto;
  margin-top: 1rem;
  width: 80%;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  border: 2px solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(100.15deg, #ffb500 45.1%, #00c2b9 103.17%);
}

.starter-mobile-inner {
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem;
}

.starter-button-container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  position: relative;
  right: 0;
  top: -2px;
}
.starter-button-mobile-left {
  padding: 0.2rem 2rem;
  color: #fff;
  background-color: #ffb500;
  border-bottom-left-radius: 10px;
}

.starter-button-mobile-right {
  padding: 0.2rem 2rem;
  color: #fff;
  background-color: #00c2b9;
  border-bottom-right-radius: 10px;
}

.greyAMob {
  position: relative;
  top: 7px;
  width: 10px;
  height: 10px;
}

.mobile-contents-container {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.starter-mobile-container {
  margin-top: 10rem;
}

.mobile-contents-sub-container {
  justify-content: center;
  width: 100%;
  margin: auto;
  margin-top: 2rem;
}

.mobileribon {
  top: -7px;
  left: -7px;
  width: 75px;
  height: 75px;
}

.slot-select {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 2px 5px;
}

.slot-select:focus {
  outline: none;
}

.full-screen-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000040;
  z-index: 9999;
}
