.visible-xs {
  display: none !important;
}

.hidden-xs {
  display: block !important;
}

@media (max-width: 1650px) {
  /* .notification-active {
		padding-right: 5rem;
	} */
}

@media (max-width: 1400px) {
  body,
  html {
    font-size: 14px;
  }

  /* .notification-active {
		padding-right: 3rem;
	} */

  .first-product-tab {
    padding: 1rem;
  }

  .tabs-col-btn .nav-link {
    padding: 0.7rem 0.5rem;
  }

  .street-eleven {
    gap: 0.25rem;
  }

  .change-of-word-inner {
    left: 21.35rem;
  }

  /* .next-prev-svg {
    right: -16.75rem;
  } */
}

@media (max-width: 1200px) {
  body,
  html {
    font-size: 13px;
  }

  .header-one-inner {
    padding: 0rem;
  }

  .payment-inner {
    padding: 1rem;
  }

  .payment-inner {
    height: 10rem;
  }

  .coin-charge-col h3 {
    font-size: 1.7rem;
  }

  .group-pro-1 {
    padding-right: 0.75rem;
    width: 33.3333%;
  }

  .width-devide {
    width: 33.3333%;
    padding-right: 0.75rem;
  }

  .group-pro-2 {
    margin-top: 1rem;
  }

  .group-pro-3 {
    margin-top: 1rem;
  }

  .width-devide-1 {
    width: 33.33333%;
    padding-right: 0.75rem;
  }

  .active-smart-tick {
    margin-right: 0rem;
  }

  .tabs-col-btn .nav-link {
    gap: 0.25rem;
  }

  .change-of-word-inner {
    left: 23rem;
  }

  /* .next-prev-svg {
    right: -18rem;
  } */
}

@media (max-width: 1070px) {
  .body-one tr td {
    padding: 0.75rem;
  }

  .head-one tr th {
    padding: 0.75rem;
  }

  .calendar {
    padding: 0.5rem;
  }

  .notification-active {
    padding-right: 0rem;
  }

  .coin-charge {
    padding: 1rem;
    min-height: 9rem;
  }

  .coin-charge-col h3 {
    font-size: 1.6rem;
  }

  .collect-market .collect-inner-1 {
    padding: 0rem 0.25rem;
  }

  .total-block {
    flex-wrap: wrap;
    gap: 2rem;
  }

  .body-one tr td {
    font-size: 0.75rem;
  }

  .total-table-col .body-one tr td p {
    padding: 0.63rem;
  }

  /* .next-prev-svg {
    right: -17.5rem;
  } */
}

@media (max-width: 992px) {
  .heading-five2 table {
    min-width: 90rem !important;
  }

  .original-price-inner h6 {
    padding-bottom: 0rem;
  }

  .hero-sec {
    padding-left: 0rem;
  }

  .calendar h4 {
    font-size: 1.25rem;
  }

  .calendar p {
    font-size: 0.813rem;
  }

  .calendar {
    height: 7.25rem;
  }

  .heading-five {
    overflow-x: scroll;
    min-width: 100%;
    width: 100%;
  }

  .heading-five table {
    min-width: 30rem;
    max-width: initial;
  }

  .heading-five .heading-two-inner {
    min-width: 30rem;
    max-width: initial;
  }

  .payment-inner h4 {
    padding-top: 2rem;
  }

  .history-col {
    padding-left: 0rem;
    padding-right: 0;
  }

  .history-col-inner {
    padding-left: 0rem;
    padding-right: 0;
  }

  .history-col-inner h5 {
    padding-left: 0rem;
  }

  .heading-five1 table {
    min-width: 65rem;
    max-width: initial;
  }

  .first-product-tab {
    padding: 1rem;
  }

  .product-collect-nav .nav-link {
    padding: 0rem 0.5rem;
  }

  .group-name {
    gap: 0.5rem !important;
  }

  .group-name h6 {
    font-size: 0.84rem;
  }

  .group-pro-2 {
    width: 100%;
  }

  .group-pro-1 {
    width: 100%;
  }

  /* .product-label {
    margin-top: 1rem;
  } */

  .group-pro-3 {
    width: 100%;
  }

  .payment-detail-1 {
    flex-wrap: wrap;
    justify-content: center;
  }

  /* .total-block .d-flex {
    flex-wrap: wrap;
    justify-content: center;
  } */

  .width-select-1 {
    margin-top: 0rem !important;
  }

  .hr2 {
    width: 100%;
    margin-left: 0;
  }

  .heading-five3 table {
    min-width: 110rem !important;
  }

  .order-manage-5 {
    padding-top: 2rem;
  }

  .order-manage-6 {
    padding-top: 2rem;
  }

  .acc-setting-col {
    padding: 1rem;
  }

  .acc-setting-col-1 {
    flex-wrap: wrap;
    justify-content: start !important;
  }

  .acc-setting-col-2 {
    flex-wrap: wrap;
    justify-content: start !important;
  }

  .category-maping-col .nav-link.active {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.75rem;
  }
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }

  .s2-inner {
    padding-left: 0rem;
    margin-top: 1rem;
  }

  .acc-info::after {
    display: none;
  }

  .main-hero-sec {
    padding: 0.5rem;
  }

  .under-heading-1 {
    padding: 0.5rem 0;
  }

  .form-row {
    padding-left: 0rem;
  }

  .plan-col {
    border-radius: 1rem;
  }

  .plan-col-2 {
    border-left: 0px solid #e4e6ed;
    border-right: 0px solid #e4e6ed;
  }

  .make-payment {
    text-align: center;
  }

  .payment-detail2 {
    flex-wrap: wrap;
    justify-content: end;
  }

  .pagination-nav-ul {
    gap: 1rem !important;
    flex-direction: row !important;
  }

  .search-btn {
    margin-top: 1rem;
    justify-content: end !important;
  }

  .group-name {
    justify-content: center;
  }

  .selected-num h4 {
    text-align: center;
  }

  .width-devide-1 {
    width: 50%;
  }

  .width-devide {
    width: 50%;
  }

  .acc-setting-col {
    margin-left: 0;
  }

  .acc-setting-col-2 .col-md-1 .apply-btn {
    margin-top: 1rem;
  }

  .license-inner {
    width: 100%;
  }

  .payment-detail .search-btn {
    margin-top: 0;
  }

  .search-btn .save-btn {
    width: 6rem;
  }

  .write-new-sec .search-btn .save-btn {
    width: 10rem;
  }

  .category-maping-col .nav-link.active {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 0.85rem;
  }

  .product-collection-modal-1 .product-collection-modal-inner h6 {
    font-size: 1rem;
  }

  .first-product-tab .search-btn .save-btn {
    width: 9rem;
  }

  .attributes1 {
    margin-top: 1rem;
  }

  .id-enter label {
    padding-top: 1rem;
  }

  .dustbin {
    margin-top: 1rem !important;
  }

  .add-tag-btn {
    margin-top: 1rem;
  }

  .product-label {
    padding-left: 0.5rem !important;
  }

  @media (max-width: 450px) {
    .mobile-dp-none {
      display: none !important;
    }

    .heading-five {
      overflow-x: hidden;
    }
    .mt-2-xs {
      margin-top: 0.5rem !important;
    }
    .moblie-width100 {
      width: 100% !important;
    }
    .mobile-height-50vh {
      height: 50rem !important;
    }
    .mobile-flex-column {
      flex-direction: column !important;
    }
    .mobile-justify-end {
      justify-content: end !important;
    }
  }

  .imges-line-nine {
    padding-left: 0.5rem;
    width: 14%;
  }

  .basic-btn {
    width: 100% !important;
  }

  .plus-img-inner {
    width: 100%;
    margin-bottom: 1rem;
  }

  .save-btn1x {
    margin-top: 1rem;
    width: 100% !important;
  }

  .change-of-word-inner {
    display: none;
  }

  .change-of-word {
    gap: 1rem;
  }

  .apply-btn1 {
    margin-top: 1rem;
  }

  .number-character1x {
    position: relative;
  }

  /* .next-prev-svg {
    position: absolute;
    top: 2.45rem;
    right: 1.5rem;
  } */

  .attributes1x {
    margin-top: 1rem !important;
  }
}

@media (max-width: 570px) {
  .imges-line-nine {
    padding-left: 0.5rem;
    width: 16%;
  }
}

@media (max-width: 450px) {
  .sidenav {
    display: none !important;
  }
  .cancel-btn {
    width: 10rem;
  }

  .under-heading-1 h6 {
    font-size: 0.85rem;
  }

  .payment-detail {
    padding: 0.75rem;
  }

  .payment-detail-inner span {
    padding: 1rem;
    font-size: 0.7rem;
  }

  .width-devide-1 {
    width: 100%;
  }

  .width-devide {
    width: 100%;
  }

  .date-input {
    padding: 0.5rem;
  }

  .findfile-btn {
    font-size: 0.8rem;
  }

  .modal-header {
    padding: 1rem;
  }

  .modal-body {
    padding: 0 1rem 2rem 1rem;
  }

  .product-collection-modal-inner {
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 1rem;
  }

  .imges-line-nine1x {
    flex-wrap: wrap;
    justify-content: center;
  }

  .imges-line-nine {
    padding-left: 0.25rem;
    width: 20%;
  }
}

@media (max-width: 450px) {
  .collectLogDetailInfoTitle {
    width: 30% !important;
  }
  .heading-five table {
    min-width: 0;
  }
  .heading-five .heading-two-inner {
    min-width: 0;
  }
  .calendar {
    width: 11rem !important;
    /* height: 10rem !important; */
  }
  .calendar h4 {
    font-size: 2rem;
  }

  .calendar p {
    font-size: 0.9rem;
  }
  .heading-five2 table {
    min-width: 10rem !important;
    width: 100% !important;
  }
}
