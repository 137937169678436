.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 600px;
  overflow-y: auto;
  background-color: #fff;
  padding: 10px;
  z-index: 10;
  box-shadow: 1px 4px 4px 0px #00000080;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-container2 {
  position: relative;
  width: 500px;
  height: 600px;
  overflow-y: auto;
  background-color: #fff;
  padding: 10px;
  z-index: 10;
  box-shadow: 1px 4px 4px 0px #00000080;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup-img {
  width: 480px;
  height: 480px;
}

.popup-link-btn:hover {
  color: white;
  background-color: #38c7c9;
}

.popup-link-btn {
  width: 100%;
  background-color: #00b8ba;
  color: white;
  font-family: "SCDream5";
  padding: 0.3rem;
  text-align: center;
  border-radius: 10px;
}
